import { useState, useEffect } from "react";
import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";
import mousePointer from "../../assets/images/mouse_pointer.png";

const CustomCursor = () => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [isVisible, setIsVisible] = useState(false);
	const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);

	const handleMouseMove = (e) => {
		setMousePosition({
			x: e.clientX,
			y: e.clientY,
		});
		if (!isVisible) setIsVisible(true);
	};

	const handleMouseLeave = () => {
		setIsVisible(false);
	};

	useEffect(() => {
		if (!isDesktop) {
			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseleave", handleMouseLeave);

			return () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseleave", handleMouseLeave);
			};
		}
	}, [isDesktop]);

	if (isDesktop) {
		return null;
	}

	return (
		<div
			className={`fixed pointer-events-none transition-all duration-100 ease-out ${
				isVisible ? "opacity-100" : "opacity-0"
			}`}
			style={{
				left: `${mousePosition.x + 25}px`,
				top: `${mousePosition.y + 5}px`,
				zIndex: 9999,
			}}
		>
			<img src={mousePointer} alt="커서 아이콘" className="w-10 h-auto" />
		</div>
	);
};

export default CustomCursor;
