import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchPopups } from "../../services/popupService";
import popupCheck from "../../assets/images/popup_check.svg";

const Popup = () => {
	const navigate = useNavigate();
	const [popups, setPopups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadPopups = async () => {
			try {
				const data = await fetchPopups();
				// 오늘 하루 보지 않기로 한 팝업 필터링
				const filteredPopups = data.filter((popup) => {
					const hideUntil = sessionStorage.getItem(`popup_${popup.id}`);
					if (!hideUntil) return true;
					return new Date(hideUntil) < new Date();
				});
				setPopups(filteredPopups);
			} catch (err) {
				setError("팝업을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPopups();
	}, []);

	const handleHideToday = (popupId) => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		tomorrow.setHours(0, 0, 0, 0);
		sessionStorage.setItem(`popup_${popupId}`, tomorrow.toISOString());
		setPopups((prev) => prev.filter((popup) => popup.id !== popupId));
	};

	const handleClose = (popupId) => {
		setPopups((prev) => prev.filter((popup) => popup.id !== popupId));
	};

	const handleDetailClick = (popup) => {
		if (popup.link) {
			window.location.href = popup.link;
		} else if (popup.post) {
			navigate(`/news/promotion/${popup.post}`);
		} else if (popup.instagram_post) {
			navigate(`/news/sns/${popup.instagram_post}`);
		} else {
			navigate("/news/promotion", { replace: true });
		}
	};

	if (loading || error || popups.length === 0) return null;

	return (
		<div className="absolute inset-0 z-[150] flex items-start justify-center bg-black/50 min-h-screen">
			<div className="relative flex flex-wrap justify-center gap-6 max-w-7xl mx-4 mt-20">
				{popups.map((popup) => (
					<div
						key={popup.id}
						className="relative bg-white rounded-2xl shadow-2xl max-w-[450px] h-fit overflow-hidden"
					>
						{/* 팝업 내용 */}
						<div className="flex flex-col">
							<img
								src={popup.image}
								alt={popup.title}
								className="w-full h-auto cursor-pointer "
								onClick={() => handleDetailClick(popup)}
							/>
							<div className="bg-white/90 backdrop-blur-sm p-4 flex justify-between items-center font-pretendard">
								<button
									onClick={() => handleHideToday(popup.id)}
									className="text-[14px] text-gray-600 hover:text-gray-800 flex items-center gap-2"
								>
									<img src={popupCheck} alt="팝업 닫기" className="w-5 h-5" />
									오늘 하루 다시 보지 않기
								</button>
								{/* 닫기 버튼 */}
								<button
									onClick={() => handleClose(popup.id)}
									className="text-primaryText hover:text-gray-200 text-[14px]"
									aria-label="팝업 닫기"
								>
									닫기
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Popup;
