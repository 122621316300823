import { useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import MenuDetailDialog from "./MenuDetailDialog";
import newTag from "../../assets/images/new.png";
import bestTag from "../../assets/images/best.png";
// 메뉴 태그 우선순위 결정 함수
const getMenuTag = (menu) => {
	if (menu.is_new) {
		return {
			image: newTag,
		};
	}
	if (menu.is_best) {
		return {
			image: bestTag,
		};
	}
	return {
		image: "hidden",
	};
};

const MenuCard = ({ item, isWide = false }) => {
	const menuTag = getMenuTag(item) || "";
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleClick = () => {
		setIsDialogOpen(true);
	};

	return (
		<>
			<div
				onClick={handleClick}
				key={item.id}
				className={`relative group bg-white  flex flex-col items-center justify-between border-[0.7px] border-[#DCDDDD] cursor-pointer
						   ${
									isWide
										? "w-[220px] h-[237px] mobtab:w-[396.454px] mobtab:h-[426.7px]"
										: "w-[160px] h-[237px] mobtab:w-[291.7px] mobtab:h-[426.7px]"
								}
						   hover:shadow-lg transition-all duration-300 ease-out`}
			>
				<div
					className={`mb-[-5%] translate-x-[-8%] translate-y-[-28%] h-[12%] font-urbane text-[10px] mobtab:text-[18px] font-bold self-start`}
				>
					<img
						src={menuTag.image}
						alt="menu tag"
						className={`h-full w-auto ${menuTag.image}`}
					/>
				</div>
				<div className="w-[90%] mx-auto aspect-square self-center overflow-hidden">
					<ProgressiveImage
						src={item.sub_image || item.image}
						placeholder="/placeholder.jpg"
						className="w-full"
						error="/error.jpg"
						cache
					>
						{(src, loading) => (
							<div className="w-full h-full">
								{loading ? (
									<div className="animate-pulse w-full h-full">
										<div className="w-full h-full bg-gray-200 rounded-lg" />
									</div>
								) : (
									<img
										src={src}
										alt={item.name}
										draggable="false"
										className="h-full w-full select-none touch-pan-y object-contain transition-all duration-300 ease-out scale-90 group-hover:scale-100"
									/>
								)}
							</div>
						)}
					</ProgressiveImage>
				</div>
				<div className="w-full flex flex-col items-center font-pretendard mt-[5%] mb-[8%]">
					<p className="text-[14px] mobtab:text-[23px] font-semibold text-[#3E3A39]">
						{item.name}
					</p>
					<p className="text-[12px] mobtab:text-[14px] font-medium text-[#9FA0A0]">
						{item.name_eng}
					</p>
				</div>
				<div
					className="text-center text-[12px] w-full mobtab:px-8 py-[6px] mobtab:py-2 font-bold
						relative bg-primary text-white overflow-hidden
						transition-all duration-300 ease-out
						group-hover:bg-lightgray group-hover:text-primary
						before:content-['']
						before:absolute before:bottom-[-100%] before:left-[-100%]
						before:w-[300%] before:h-[300%]
						before:bg-primary
						before:rotate-45
						before:transition-transform before:duration-500 before:ease-out
						group-hover:before:translate-x-[35%] group-hover:before:translate-y-[-35%]
						[&>p]:relative [&>p]:z-10"
				>
					<p className="font-urbane font-semibold text-[8px] tablet:text-[12px]">
						MORE &nbsp; +
					</p>
				</div>
			</div>

			<MenuDetailDialog
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				menu={item}
			/>
		</>
	);
};

export default MenuCard;
