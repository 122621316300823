import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

const fetchPrivacyPolicy = async () => {
	try {
		const response = await fetch(
			`${BASE_URL}/documents/api/legal-documents/privacy/`
		);
		if (!response.ok) throw new Error("Failed to fetch privacy policy");
		return await response.json();
	} catch (error) {
		console.error("Error fetching privacy policy:", error);
		throw error;
	}
};

const fetchTermsOfService = async () => {
	try {
		const response = await fetch(
			`${BASE_URL}/documents/api/legal-documents/terms/`
		);
		if (!response.ok) throw new Error("Failed to fetch terms of service");
		return await response.json();
	} catch (error) {
		console.error("Error fetching terms of service:", error);
		throw error;
	}
};

const useDocumentStore = create((set) => ({
	// 상태
	privacyPolicy: null,
	termsOfService: null,
	loading: false,
	error: null,

	// 액션
	getPrivacyPolicy: async () => {
		try {
			set({ loading: true, error: null });

			// 이미 데이터가 있는지 확인
			const existingPolicy = useDocumentStore.getState().privacyPolicy;
			if (existingPolicy) return existingPolicy;

			// 없으면 새로 fetch
			const policyData = await fetchPrivacyPolicy();

			set({
				privacyPolicy: policyData,
				loading: false,
			});

			return policyData;
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
			throw error;
		}
	},

	getTermsOfService: async () => {
		try {
			set({ loading: true, error: null });

			// 이미 데이터가 있는지 확인
			const existingTerms = useDocumentStore.getState().termsOfService;
			if (existingTerms) return existingTerms;

			// 없으면 새로 fetch
			const termsData = await fetchTermsOfService();

			set({
				termsOfService: termsData,
				loading: false,
			});

			return termsData;
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
			throw error;
		}
	},

	// 초기화
	resetDocuments: () => {
		set({ privacyPolicy: null, termsOfService: null, error: null });
	},
}));

export default useDocumentStore;
