import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

// API 함수
const fetchRegions = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/regions`);
		if (!response.ok) throw new Error("지역 정보를 불러오는데 실패했습니다");
		return await response.json();
	} catch (error) {
		console.error("Error fetching regions:", error);
		throw error;
	}
};

// Zustand store
const useStoreStore = create((set, get) => ({
	// 상태
	regions: [],
	loading: false,
	error: null,
	selectedRegion: null,

	// 액션
	getRegions: async () => {
		try {
			// 이미 데이터가 있는지 확인
			const existingRegions = get().regions;
			if (existingRegions.length > 0) return;

			set({ loading: true, error: null });
			const data = await fetchRegions();
			set({
				regions: data,
				loading: false,
			});
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
		}
	},

	setSelectedRegion: (regionId) => {
		const region = get().regions.find((r) => r.id === regionId);
		set({ selectedRegion: region });
	},

	// 초기화
	reset: () => {
		set({
			regions: [],
			loading: false,
			error: null,
			selectedRegion: null,
		});
	},
}));

export default useStoreStore;
