const SkeletonNewsCard = () => {
	return (
		<div className="animate-pulse">
			<div className="flex flex-col w-[285px]">
				{/* 이미지 스켈레톤 */}
				<div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg"></div>

				{/* 텍스트 영역 스켈레톤 */}
				<div className="py-[12.58px] px-[10.9px] h-[86.1px]">
					<div className="h-4 w-20 bg-gray-200 rounded mb-2"></div>
					<div className="h-6 w-48 bg-gray-200 rounded"></div>
				</div>
			</div>
		</div>
	);
};

export default SkeletonNewsCard;
