import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
import FranchisePage from "./pages/FranchisePage";
import StorePage from "./pages/StorePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import BrandPage from "./pages/BrandPage";
import NewsPage from "./pages/NewsPage";
import CustomCursor from "./components/common/CustomCursor";
import { HelmetProvider } from "react-helmet-async";
import TestPage from "./pages/TestPage";
function App() {
	return (
		<HelmetProvider>
			<CustomCursor />
			<Router basename="/">
				<div className="flex flex-col min-h-screen overflow-hidden">
					<div className="fixed top-0 left-0 w-full z-[200]">
						<Navbar />
					</div>
					<main className="flex-grow desktop:pt-[calc(var(--nav-height)*1px)] pt-[65px]">
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/menu" element={<MenuPage />} />
							<Route path="/menu/total" element={<MenuPage />} />
							<Route path="/menu/category/:categoryId" element={<MenuPage />} />
							<Route path="/franchise" element={<FranchisePage />} />
							<Route path="/locations" element={<StorePage />} />
							<Route path="/privacy" element={<PrivacyPolicyPage />} />
							<Route path="/terms" element={<TermsOfServicePage />} />
							<Route path="/brand" element={<BrandPage />} />
							<Route
								path="/news"
								element={<NewsPage selectedTab="프로모션" />}
							/>
							<Route
								path="/news/promotion"
								element={<NewsPage selectedTab="프로모션" />}
							/>
							<Route
								path="/news/sns"
								element={<NewsPage selectedTab="SNS" />}
							/>
							<Route
								path="/news/promotion/:id"
								element={<NewsPage selectedTab="프로모션" />}
							/>
							<Route
								path="/news/sns/:id"
								element={<NewsPage selectedTab="SNS" />}
							/>
							<Route path="/test" element={<TestPage />} />
						</Routes>
					</main>
				</div>
			</Router>
		</HelmetProvider>
	);
}

export default App;
