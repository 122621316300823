import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";
import useBannerStore from "../../services/bannerService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./MainBannerSection.css";
import ProgressiveImage from "react-progressive-graceful-image";

const MainBannerSection = () => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { banners, getBanners } = useBannerStore();

	useEffect(() => {
		const loadBanners = async () => {
			try {
				await getBanners();
			} catch (err) {
				setError("배너를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadBanners();
	}, []);

	const handleBannerClick = (banner) => {
		if (banner.link) {
			window.location.href = banner.link;
		} else if (banner.post) {
			navigate(`/news/promotion/${banner.post}`);
		}
	};

	if (loading)
		return (
			<div className="relative w-full pb-[33.67%] bg-gray-100">
				<div className="absolute inset-0 flex justify-center items-center">
					<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
				</div>
			</div>
		);

	if (error)
		return (
			<div className="relative w-full pb-[33.67%] bg-gray-100">
				<div className="absolute inset-0 flex justify-center items-center text-red-600">
					{error}
				</div>
			</div>
		);

	if (banners.length === 0) return null;

	return (
		<div className="relative bg-white">
			<Swiper
				modules={[Autoplay, Pagination]}
				spaceBetween={0}
				slidesPerView={1}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
					renderBullet: function (index, className) {
						return `
							<div class="${className} w-[30px] h-[30px] flex justify-center items-center">
								<div class="bullet-content w-full h-full border-2 border-white rounded-full"></div>
							</div>
						`;
					},
				}}
				className="banner-swiper w-full"
			>
				{banners.map((banner) => (
					<SwiperSlide key={banner.id}>
						<div
							className="w-full cursor-pointer"
							onClick={() => handleBannerClick(banner)}
							role="button"
							tabIndex={0}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handleBannerClick(banner);
								}
							}}
						>
							<ProgressiveImage
								src={
									isMobile && banner.mobile_image
										? banner.mobile_image
										: banner.image
								}
								placeholder="/placeholder.jpg"
								error="/error.jpg"
								cache
							>
								{(src, loading) => (
									<div className="w-full h-full">
										{loading ? (
											<div className="w-full aspect-[2.97/1] animate-pulse">
												<div className="w-screen h-full bg-gray-200" />
											</div>
										) : (
											<img
												src={src}
												alt={`배너 이미지 ${banner.id}`}
												className="w-screen h-auto"
											/>
										)}
									</div>
								)}
							</ProgressiveImage>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default MainBannerSection;
