import { useEffect, useRef, useState } from "react";
import MenuCard from "../../components/home/MenuCard";
import TitleAndMore from "../../components/home/TitleAndMore";
import useMenuStore from "../../services/menuService";
import { useInView } from "react-intersection-observer";

const BestMenuSection = () => {
	const { categories, loading, error, getCategories } = useMenuStore();

	const signatureMenus = categories
		.flatMap((category) => category.menus)
		.filter((menu) => menu.is_best);

	const [scrollProgress, setScrollProgress] = useState(5);
	const scrollContainerRef = useRef(null);
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	const [touchStartX, setTouchStartX] = useState(null);
	const [touchStartY, setTouchStartY] = useState(null);
	const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);
	const [scrollVelocity, setScrollVelocity] = useState(0);
	const [lastTouchX, setLastTouchX] = useState(null);
	const [lastTouchTime, setLastTouchTime] = useState(null);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [startX, setStartX] = useState(null);
	const [scrollLeft, setScrollLeft] = useState(0);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	useEffect(() => {
		const handleScroll = () => {
			if (!scrollContainerRef.current) return;

			const container = scrollContainerRef.current;
			const scrollLeft = container.scrollLeft;
			const scrollWidth = container.scrollWidth;
			const clientWidth = container.clientWidth;
			const maxScroll = scrollWidth - clientWidth;

			// 스크롤이 불가능한 경우
			if (maxScroll <= 0) {
				setScrollProgress(0);
				return;
			}

			// 스크롤 진행률 계산 (5-100 사이의 값)
			const rawProgress = (scrollLeft / maxScroll) * 100;
			const adjustedProgress =
				scrollLeft === 0
					? 5
					: scrollLeft >= maxScroll
					? 100
					: 5 + rawProgress * 0.95;

			setScrollProgress(adjustedProgress);
		};

		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			// ResizeObserver를 사용하여 컨테이너 크기 변경 감지
			const resizeObserver = new ResizeObserver(() => {
				handleScroll();
			});
			resizeObserver.observe(scrollContainer);

			// 초기 로드 시 약간의 지연을 두고 계산
			setTimeout(handleScroll, 100);

			// 스크롤과 드래그 모두에 대해 이벤트 처리
			const events = ["scroll", "mousemove", "touchmove"];
			events.forEach((event) => {
				scrollContainer.addEventListener(event, handleScroll, {
					passive: true,
				});
			});

			return () => {
				events.forEach((event) => {
					scrollContainer.removeEventListener(event, handleScroll);
				});
				resizeObserver.disconnect();
			};
		}
	}, [categories]);

	const handleTouchStart = (e) => {
		setTouchStartX(e.touches[0].clientX);
		setTouchStartY(e.touches[0].clientY);
		setIsHorizontalScroll(false);
	};

	const handleTouchMove = (e) => {
		if (!touchStartX || !touchStartY) return;

		const currentTime = Date.now();
		const currentTouchX = e.touches[0].clientX;

		// 속도 계산
		if (lastTouchX !== null && lastTouchTime !== null) {
			const deltaX = lastTouchX - currentTouchX;
			const deltaTime = currentTime - lastTouchTime;
			setScrollVelocity(deltaX / deltaTime);
		}

		setLastTouchX(currentTouchX);
		setLastTouchTime(currentTime);

		const deltaX = Math.abs(currentTouchX - touchStartX);
		const deltaY = Math.abs(e.touches[0].clientY - touchStartY);

		if (!isHorizontalScroll && (deltaX > 5 || deltaY > 5)) {
			setIsHorizontalScroll(deltaX > deltaY);
		}

		if (isHorizontalScroll && scrollContainerRef.current) {
			const walk = touchStartX - currentTouchX;
			scrollContainerRef.current.scrollLeft += walk * 0.5;
			setTouchStartX(currentTouchX);

			// 스크롤 진행률 계산 로직...
		}
	};

	const handleTouchEnd = () => {
		if (scrollContainerRef.current && scrollVelocity !== 0) {
			const startVelocity = scrollVelocity * 10; // 매우 낮은 초기 속도
			let currentVelocity = startVelocity;

			const animate = () => {
				if (Math.abs(currentVelocity) > 0.1 && scrollContainerRef.current) {
					scrollContainerRef.current.scrollLeft += currentVelocity;
					currentVelocity *= 0.98; // 매우 천천히 감속

					// 스크롤 진행률 업데이트
					const container = scrollContainerRef.current;
					const scrollLeft = container.scrollLeft;
					const scrollWidth = container.scrollWidth;
					const clientWidth = container.clientWidth;
					const maxScroll = scrollWidth - clientWidth;

					if (maxScroll > 0) {
						const rawProgress = (scrollLeft / maxScroll) * 100;
						const adjustedProgress =
							scrollLeft === 0
								? 5
								: scrollLeft >= maxScroll
								? 100
								: 5 + rawProgress * 0.95;
						setScrollProgress(adjustedProgress);
					}

					requestAnimationFrame(animate);
				}
			};

			requestAnimationFrame(animate);
		}

		setTouchStartX(null);
		setTouchStartY(null);
		setIsHorizontalScroll(false);
		setScrollVelocity(0);
		setLastTouchX(null);
		setLastTouchTime(null);
	};

	const handleMouseDown = (e) => {
		setIsMouseDown(true);
		setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
		setScrollLeft(scrollContainerRef.current.scrollLeft);
	};

	const handleMouseMove = (e) => {
		if (!isMouseDown) return;
		e.preventDefault();

		const x = e.pageX - scrollContainerRef.current.offsetLeft;
		const walk = (x - startX) * 1.5;
		scrollContainerRef.current.scrollLeft = scrollLeft - walk;
	};

	const handleMouseUp = () => {
		setIsMouseDown(false);
	};

	const handleMouseLeave = () => {
		setIsMouseDown(false);
	};

	if (loading)
		return (
			<div className="flex justify-center items-center pt-[60px] tablet:pt-[105px]">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center pt-[60px] tablet:pt-[105px] text-red-600">
				{error}
			</div>
		);

	return (
		<section
			className="pt-[60px] tablet:pt-[105px] bg-white relative"
			ref={sectionRef}
		>
			<div className="mx-auto max-w-7xl px-6 mb-8 tablet:mb-16">
				<TitleAndMore
					title="Best Menu"
					to="/menu"
					titleClassName={`${inView ? "animate-fade-slide-left-to-right" : ""}`}
					className={`${inView ? "animate-fade-slide-right-to-left" : ""}`}
				/>
			</div>

			<div className="relative w-full">
				<div
					ref={scrollContainerRef}
					className="flex overflow-x-auto scrollbar-hide mb-[20.45px] tablet:mb-[52.6px] touch-pan-x cursor-grab active:cursor-grabbing"
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseLeave}
					style={{
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						WebkitOverflowScrolling: "touch",
						overscrollBehavior: "x contain",
						userSelect: "none",
						touchAction: isHorizontalScroll ? "pan-x" : "auto",
					}}
				>
					<div
						className={`flex flex-row gap-[50px] tablet:gap-[50px] ml-[max(16px,(100vw-1280px)/2+16px)] tablet:ml-[max(24px,(100vw-1280px)/2+24px)] ${
							inView ? "animate-fade-slide-left-to-right" : ""
						}`}
					>
						{signatureMenus.map((item, index) => (
							<div key={item.id} className="pt-[10px]">
								<MenuCard
									title={item.name}
									engTitle={item.name_eng}
									imageUrl={item.image}
								/>
							</div>
						))}
					</div>
				</div>
				{/* 스크롤 바 */}
				<div className="mx-auto max-w-7xl px-6">
					<div className="h-[5.13px] bg-gray-200">
						<div
							className="h-full bg-gray500 transition-all duration-300 ease-out"
							style={{
								width: `${Math.max(scrollProgress, 5)}%`,
								transform: "translateZ(0)",
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BestMenuSection;
