import { useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";

const BrandCarousel = ({ cardData }) => {
	const [width, setWidth] = useState(0);
	const [goToSlide, setGoToSlide] = useState(0);
	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);
	const [autoPlayEnabled, setAutoPlayEnabled] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		let autoPlayTimer;

		if (autoPlayEnabled) {
			autoPlayTimer = setInterval(() => {
				setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
			}, 2000);
		}

		return () => {
			if (autoPlayTimer) {
				clearInterval(autoPlayTimer);
			}
		};
	}, [autoPlayEnabled]);

	const slides = cardData.map((card, index) => ({
		key: index,
		content: (
			<div
				className={`relative overflow-hidden group w-[400px] px-[20%] max-w-[${
					width * 0.9
				}px]`}
			>
				<img
					src={card.image}
					alt={`slide ${index + 1}`}
					className={`w-full h-full object-cover  max-w-[${
						width * 0.9
					}px] rounded-full`}
				/>
				<div className="absolute inset-0 flex flex-col justify-center items-center">
					<h3
						className="text-white text-[32px] font-bold mb-4 font-rucksack"
						dangerouslySetInnerHTML={{ __html: card.title }}
					/>
					<p
						className="text-white text-center text-[14px] font-pretendard font-medium"
						dangerouslySetInnerHTML={{ __html: card.copy }}
					/>
				</div>
			</div>
		),
		onClick: () => setGoToSlide(index),
	}));

	const handleTouchStart = (e) => {
		setAutoPlayEnabled(false);
		setTouchStart(e.touches[0].clientX);
	};

	const handleTouchMove = (e) => {
		setTouchEnd(e.touches[0].clientX);
	};

	const handleTouchEnd = () => {
		if (!touchStart || !touchEnd) return;

		const distance = touchStart - touchEnd;
		const minSwipeDistance = 50;

		if (Math.abs(distance) < minSwipeDistance) return;

		if (distance > 0) {
			setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
		} else {
			setGoToSlide((prev) => (prev + 1) % slides.length);
		}

		setTouchStart(null);
		setTouchEnd(null);

		setTimeout(() => {
			setAutoPlayEnabled(true);
		}, 3000);
	};

	const handlePrevClick = () => {
		setAutoPlayEnabled(false);
		setGoToSlide((prev) => (prev + 1) % slides.length);
		setTimeout(() => {
			setAutoPlayEnabled(true);
		}, 3000);
	};

	const handleNextClick = () => {
		setAutoPlayEnabled(false);
		setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
		setTimeout(() => {
			setAutoPlayEnabled(true);
		}, 3000);
	};

	return (
		<div className="w-full relative">
			<div
				className="h-[min(480px,115vw)] mb-[20px] relative"
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<Carousel
					slides={slides}
					goToSlide={goToSlide}
					offsetRadius={1}
					showNavigation={false}
					animationConfig={config.gentle}
					offsetFactor={0.8}
				/>
				<button
					onClick={handlePrevClick}
					className="z-[1] absolute left-[calc(50%-190px)] top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center transition-all"
					aria-label="이전 슬라이드"
				>
					<svg
						className="w-5 h-5"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M15 19l-7-7 7-7"
						/>
					</svg>
				</button>
				<button
					onClick={handleNextClick}
					className="z-[1] absolute right-[calc(50%-190px)] top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center transition-all"
					aria-label="다음 슬라이드"
				>
					<svg
						className="w-5 h-5"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default BrandCarousel;
