import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

// API 함수들
const fetchOpeningTypes = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/opening-types/`);
		if (!response.ok) throw new Error("Failed to fetch opening types");
		return await response.json();
	} catch (error) {
		console.error("Error fetching opening types:", error);
		throw error;
	}
};

const fetchFaqs = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/faqs/`);
		if (!response.ok) throw new Error("Failed to fetch FAQs");
		return await response.json();
	} catch (error) {
		console.error("Error fetching FAQs:", error);
		throw error;
	}
};

const submitInquiry = async (inquiryData) => {
	try {
		console.log("inquiryData", inquiryData);
		const response = await fetch(`${BASE_URL}/franchise/api/inquiries/`, {
			// const response = await fetch(
			// 	`http://localhost:8000/franchise/api/inquiries/`,
			// 	{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inquiryData),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(
				errorData.message ||
					`문의 접수 실패 - 상태 코드: ${
						response.status
					}, 응답: ${JSON.stringify(errorData)}`
			);
		}

		return await response.json();
	} catch (error) {
		console.error("Error submitting inquiry:", error);
		throw error;
	}
};

// Zustand store
const useFranchiseStore = create((set) => ({
	// 상태
	openingTypes: [],
	faqs: [],
	selectedType: null,
	loading: false,
	error: null,
	expandedFaq: null,
	selectedCategory: "전체",

	// 액션
	getOpeningTypesAndFaqs: async () => {
		try {
			set({ loading: true, error: null });

			const [typesData, faqsData] = await Promise.all([
				fetchOpeningTypes(),
				fetchFaqs(),
			]);

			set({
				openingTypes: typesData,
				faqs: faqsData,
				selectedType: typesData[0]?.title || null,
				loading: false,
			});
		} catch (error) {
			set({ error: error.message, loading: false });
		}
	},

	setSelectedType: (type) => set({ selectedType: type }),
	setExpandedFaq: (faqId) => set({ expandedFaq: faqId }),
	setSelectedCategory: (category) => set({ selectedCategory: category }),

	submitInquiry: async (inquiryData) => {
		try {
			set({ loading: true, error: null });
			const response = await submitInquiry(inquiryData);
			set({ loading: false });
			return response;
		} catch (error) {
			set({ error: error.message, loading: false });
			throw error;
		}
	},

	// 초기화
	reset: () => {
		set({
			openingTypes: [],
			faqs: [],
			selectedType: null,
			loading: false,
			error: null,
			expandedFaq: null,
			selectedCategory: "전체",
		});
	},
}));

export default useFranchiseStore;
