import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

// API 함수
const fetchPosts = async () => {
	try {
		const response = await fetch(
			// `${BASE_URL}/marketing/api/posts/news_and_promotion/?page=${page}${
			`${BASE_URL}/marketing/api/posts/`
		);
		if (!response.ok) throw new Error("게시물을 불러오는데 실패했습니다");
		return await response.json();
	} catch (error) {
		console.error("Error fetching posts:", error);
		throw error;
	}
};

const fetchPostDetail = async (id) => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/posts/${id}/`);
		if (!response.ok)
			throw new Error("게시물 상세 정보를 불러오는데 실패했습니다");
		return await response.json();
	} catch (error) {
		console.error("Error fetching post detail:", error);
		throw error;
	}
};

// Zustand store
const useNewsStore = create((set, get) => ({
	// 상태
	posts: [],
	currentPost: null,
	loading: false,
	error: null,
	currentPage: 1,
	selectedCategory: "",

	// 액션
	getPosts: async (page = 1, category = "") => {
		try {
			set({ loading: true, error: null });
			const data = await fetchPosts(page, category);
			set({
				posts: data,
				currentPage: page,
				selectedCategory: category,
				loading: false,
			});
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
		}
	},

	getPostDetail: async (id) => {
		try {
			set({ loading: true, error: null });
			const data = await fetchPostDetail(id);
			set({
				currentPost: data,
				loading: false,
			});
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
		}
	},

	// 초기화
	reset: () => {
		set({
			posts: [],
			currentPost: null,
			loading: false,
			error: null,
			currentPage: 1,
			selectedCategory: "",
		});
	},
}));

export default useNewsStore;
