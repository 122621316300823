import React, { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import FloatingButton from "../components/home/FloatingButton";

const TestPage = () => {
	useEffect(() => {
		// smooth-scrollbar 초기화
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			const scrollbar = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			return () => {
				if (scrollbar) scrollbar.destroy();
			};
		}
	}, []);

	return (
		<div className="w-full min-h-screen bg-white relative">
			<div id="my-scrollbar" className="w-full h-screen overflow-auto">
				{[...Array(10)].map((_, index) => (
					<div
						key={index}
						className="h-screen flex items-center justify-center text-5xl font-bold"
						style={{
							backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#e0e0e0",
						}}
					>
						섹션 {index + 1}
					</div>
				))}
			</div>
			<div className="fixed bottom-4 right-4 tablet:bottom-8 tablet:right-8 z-50">
				<FloatingButton />
			</div>
		</div>
	);
};

export default TestPage;
