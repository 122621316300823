import React, { useCallback } from "react";
import SearchInput from "./SearchInput";
// SearchFilter 컴포넌트 수정
const SearchFilter = React.memo(
	({
		searchMode,
		setSearchMode,
		searchInput,
		setSearchInput,
		handleKeyDown,
		handleLocationSearch,
		handleMarkerClick,
		handleMarkerDeselect,
		isSearching,
		selectedCity,
		setSelectedCity,
		selectedDistrict,
		setSelectedDistrict,
		districts,
		cities,
	}) => {
		const handleModeChange = useCallback(
			(mode) => {
				handleMarkerDeselect();
				setSearchMode(mode);
				if (mode === "location") {
					handleLocationSearch();
				}
			},
			[
				setSearchMode,
				handleLocationSearch,
				setSearchInput,
				handleMarkerDeselect,
			]
		);

		const handleCityChange = useCallback(
			(e) => {
				handleMarkerDeselect();
				setSelectedCity(e.target.value);
			},
			[setSelectedCity, handleMarkerDeselect]
		);

		const handleDistrictChange = useCallback(
			(e) => {
				handleMarkerDeselect();
				setSelectedDistrict(e.target.value);
			},
			[setSelectedDistrict, handleMarkerDeselect]
		);

		return (
			<div className="bg-white">
				<div className="flex flex-col">
					{/* 검색 모드 선택 */}
					<div className="flex items-center justify-center border-b h-[60px] mobile:h-[83.96px] font-bold font-noto text-[15px] shadow-md">
						<div
							className={`flex-1 h-full flex items-center justify-center cursor-pointer border-r-[1px] border-r-[#C9CACA]/30 ${
								searchMode === "location"
									? "border-b-[2.41px] border-primary text-primary"
									: "border-b-[2.41px] border-transparent"
							}`}
							onClick={() => handleModeChange("location")}
						>
							<p>내 주변에서 찾기</p>
						</div>
						<div
							className={`flex-1 h-full flex items-center justify-center cursor-pointer ${
								searchMode === "search"
									? "border-b-[2.41px] border-primary text-primary"
									: "border-b-[2.41px] border-transparent"
							}`}
							onClick={() => handleModeChange("search")}
						>
							<p>검색해서 찾기</p>
						</div>
					</div>

					<div className="flex-1 relative">
						{searchMode === "search" && (
							<SearchInput
								searchInput={searchInput}
								setSearchInput={setSearchInput}
								handleKeyDown={handleKeyDown}
								isSearching={isSearching}
							/>
						)}
					</div>

					{/* 검색 필터 */}
					{searchMode === "search" && (
						<div className="flex flex-wrap gap-4 items-center justify-center text-[13px] font-noto text-gray700 font-medium mx-4 mt-3">
							{/* 시/도 선택 */}
							<select
								value={selectedCity}
								onChange={handleCityChange}
								className="bg-white flex-1 w-full px-4 py-[10px] wide:py-2 mb-1 wide:mb-0 h-[50px] text-[16px] text-[#595757] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#595757] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold [&>*]:pr-[10px] appearance-none"
								style={{
									backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
									backgroundPosition: "right 0.5rem center",
									backgroundRepeat: "no-repeat",
									backgroundSize: "1.5em 1.5em",
								}}
							>
								<option value="">시/도 선택</option>
								{cities.map((city) => (
									<option key={city} value={city}>
										{city}
									</option>
								))}
							</select>

							{/* 구/군 선택 */}
							<select
								value={selectedDistrict}
								onChange={handleDistrictChange}
								disabled={!selectedCity || districts.length === 0}
								className="bg-white flex-1 w-full px-4 py-[10px] wide:py-2 mb-1 wide:mb-0 h-[50px] text-[16px] text-[#595757] wide:text-[18px] border-[1px] border-[#666464]/50 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder:text-[#595757] placeholder:text-[16px] wide:placeholder:text-[18px] placeholder:font-semibold disabled:bg-gray-100 [&>*]:pr-[10px] appearance-none"
								style={{
									backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
									backgroundPosition: "right 0.5rem center",
									backgroundRepeat: "no-repeat",
									backgroundSize: "1.5em 1.5em",
								}}
							>
								<option value="">시/구/군 선택</option>
								{districts.map((district) => (
									<option key={district.name} value={district.name}>
										{district.name}
									</option>
								))}
							</select>
						</div>
					)}
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		// 중요한 프롭스만 비교하여 리렌더링 결정
		return (
			prevProps.searchMode === nextProps.searchMode &&
			prevProps.searchInput === nextProps.searchInput &&
			prevProps.isSearching === nextProps.isSearching &&
			prevProps.selectedCity === nextProps.selectedCity &&
			prevProps.selectedDistrict === nextProps.selectedDistrict &&
			prevProps.districts === nextProps.districts &&
			prevProps.searchResults === nextProps.searchResults
		);
	}
);

SearchFilter.displayName = "SearchFilter";

export default SearchFilter;
