import { Helmet } from "react-helmet-async";
import MainBannerSection from "../sections/home/MainBannerSection";
import BestMenuSection from "../sections/home/BestMenuSection";
import FavoriteSection from "../sections/home/FavoriteSection";
import Popup from "../components/home/Popup";
import HomeNewsSection from "../sections/home/HomeNewsSection";
import SearchStoreSection from "../sections/home/SearchStoreSection";
import InstagramSection from "../sections/home/InstagramSection";
import TextBeltSection from "../sections/home/TextBeltSection";
import FloatingButton from "../components/home/FloatingButton";
import Scrollbar from "smooth-scrollbar";
import { useRef, useEffect } from "react";
import Footer from "../components/layout/Footer";
import useScrollStore from "../store/scrollStore";

const HomePage = () => {
	const scrollbarRef = useRef(null);
	const setScrollY = useScrollStore((state) => state.setScrollY);

	useEffect(() => {
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			// 스크롤 이벤트 리스너 추가
			scrollbarRef.current.addListener(({ offset }) => {
				setScrollY(offset.y);
			});

			// 초기 위치 설정
			scrollbarRef.current.setPosition(0, 0);

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
					scrollbarRef.current = null;
				}
			};
		}
	}, [setScrollY]);

	return (
		<>
			<Helmet>
				<title>봉천동 매운 떡볶이 | 대한민국 대표 매운맛</title>
				<meta
					name="description"
					content="봉천동 매운 떡볶이에서 즐기는 특별한 매운맛! 대한민국 대표 매운 떡볶이 프랜차이즈입니다."
				/>
				<meta
					name="keywords"
					content="봉천동 매운 떡볶이, 매운 떡볶이, 분식, 프랜차이즈"
				/>
				<link rel="canonical" href="https://bongmaetteok.co.kr" />
			</Helmet>
			<div className="w-full min-h-screen bg-white relative">
				<div id="my-scrollbar" className="w-full h-screen overflow-auto">
					<div className="content">
						<Popup />
						<MainBannerSection />
						<BestMenuSection />
						<HomeNewsSection />
						<FavoriteSection />
						<InstagramSection />
						<SearchStoreSection />
						<TextBeltSection />
						<Footer />
					</div>
				</div>
				<div className="fixed bottom-4 right-4 tablet:bottom-8 tablet:right-8 z-50">
					<FloatingButton />
				</div>
			</div>
		</>
	);
};

export default HomePage;
