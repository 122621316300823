import { useMediaQuery, BREAKPOINTS } from "../../hooks/useMediaQuery";
import floatingButtonImage from "../../assets/images/floating_button.png";
import topImage from "../../assets/images/top.png";
import mobileFloatingButtonImage from "../../assets/images/mobile_floating_button.png";
import mobileTopImage from "../../assets/images/mobile_top.png";
import { useNavigate } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";

const FloatingButton = () => {
	const navigate = useNavigate();
	const isMobileOrTablet = useMediaQuery(BREAKPOINTS.TABLET);

	const handleScrollToTop = () => {
		const scrollbar = Scrollbar.get(document.querySelector("#my-scrollbar"));
		if (scrollbar) {
			scrollbar.scrollTo(0, 0, 600);
		}
	};

	const handleNavigateToInquiry = () => {
		navigate("/franchise");
	};

	return (
		<div
			className={`flex flex-col gap-4 tablet:gap-8 z-50 ${
				isMobileOrTablet ? "w-[130px]" : "w-[215px]"
			}`}
		>
			{window.location.pathname === "/" && (
				<button
					onClick={handleNavigateToInquiry}
					className="flex items-center justify-center transition-all duration-300 hover:scale-110 w-full"
					aria-label="창업 문의하기"
				>
					<img
						src={
							isMobileOrTablet ? mobileFloatingButtonImage : floatingButtonImage
						}
						alt="창업 문의하기"
						className="w-full h-auto shadow-lg"
					/>
				</button>
			)}
			<button
				onClick={handleScrollToTop}
				className={`flex items-center justify-center transition-all duration-300 hover:scale-110 w-full`}
				aria-label="맨 위로 이동"
			>
				<img
					src={isMobileOrTablet ? mobileTopImage : topImage}
					alt="맨 위로 이동"
					className="w-full tablet:w-[70%] h-auto shadow-lg"
				/>
			</button>
		</div>
	);
};

export default FloatingButton;
