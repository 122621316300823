import { useEffect } from "react";
import useDocumentStore from "../services/documentService";

const PrivacyPolicyPage = () => {
	const { privacyPolicy, loading, error, getPrivacyPolicy } =
		useDocumentStore();

	useEffect(() => {
		getPrivacyPolicy();
	}, [getPrivacyPolicy]);

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<div className="py-16 bg-gray-50 min-h-screen">
			<div className="max-w-4xl mx-auto px-4 mobile:px-6">
				<div className="bg-white rounded-lg shadow-lg overflow-hidden">
					<div className="p-8 border-b border-gray-200">
						<h1 className="text-3xl font-bold text-center text-gray-900">
							{privacyPolicy?.title}
						</h1>
						<p className="text-center text-gray-500 mt-2">
							최종 수정일:{" "}
							{new Date(privacyPolicy?.updated_at).toLocaleDateString()}
						</p>
					</div>

					<div
						className="prose prose-lg max-w-none p-8 prose-headings:text-gray-900 prose-p:text-gray-600 prose-a:text-red-600 prose-strong:text-gray-900 prose-ul:text-gray-600"
						dangerouslySetInnerHTML={{ __html: privacyPolicy?.content }}
					/>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicyPage;
