import { Link, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import mainTextLogo from "../../assets/images/main_icon_text_logo_white.svg";
import drawerTop from "../../assets/images/drawer_top.svg";
import SocialLinks from "../common/SocialLinks";
import useMenuStore from "../../services/menuService";
import useScrollStore from "../../store/scrollStore";

const compareCurrentLocation = (href) => {
	const pathname = window.location.pathname;
	const hash = window.location.hash;

	// 브랜드 서브메뉴 경로 체크 (/brand#section)
	if (href.startsWith("/brand#")) {
		return pathname === "/brand" && href.split("#")[1] === hash.slice(1);
	}

	// /news/sns 경로일 때
	if (href === "/news") {
		return pathname.startsWith("/news");
	}

	// 메뉴 관련 경로 체크 수정
	if (href === "/menu") {
		return pathname.startsWith("/menu");
	}

	if (href === "/menu/total") {
		return pathname === "/menu/total" || pathname === "/menu";
	}

	if (href.startsWith("/menu/category/")) {
		return pathname === href;
	}

	// /brand 메인 메뉴일 때
	if (href === "/brand") {
		return pathname.startsWith("/brand");
	}

	// 정확한 경로 매칭
	return pathname === href;
};

const DrawerPanel = ({
	isDrawerOpen,
	toggleDrawer,
	logoSize,
	navItems,
	openSubmenu,
	location,
	handleMenuClick,
}) => {
	//  안전영역 하단 높이

	return (
		<div
			className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${
				isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
			}`}
			onClick={toggleDrawer}
		>
			<div
				className={`absolute text-white w-[280px] bg-[#C61E1E] transform transition-transform duration-300 ease-in-out rounded-b-lg ${
					isDrawerOpen ? "translate-y-0" : "-translate-y-full"
				} left-[-10px] ${logoSize === "small" ? "top-[80px]" : "top-[80px]"}`}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex flex-col h-[calc(100vh-150px)] pb-[env(safe-area-inset-bottom)] focus:outline-none">
					<div className="flex-1 overflow-y-auto pt-[70px] pl-9 pr-7">
						{navItems.slice(0, 4).map((item) => (
							<div key={item.name} className="">
								<div className="flex items-center justify-between focus:outline-none">
									<button
										onClick={(e) => handleMenuClick(e, item.name)}
										className={`
												py-2 
												text-[18px] 
												hover:text-accent 
												w-full 
												text-left 
												focus:outline-none
												flex
												items-center
												justify-between
												${
													compareCurrentLocation(item.href) ||
													openSubmenu === item.name
														? "text-accent font-extrabold"
														: "font-medium"
												}
											`}
									>
										{item.name}
										<svg
											className={`
													w-4 
													h-4 
													transition-transform 
													duration-300
													${openSubmenu === item.name ? "rotate-180" : "rotate-0"}
												`}
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M19 9l-7 7-7-7"
											/>
										</svg>
									</button>
								</div>
								{/* 서브메뉴 */}
								<div
									className={`
											overflow-hidden 
											transition-all 
											duration-300 
											${openSubmenu === item.name ? "max-h-[500px]" : "max-h-0"}
										`}
								>
									{item.subItems.map((subItem) => (
										<Link
											key={subItem.name}
											to={subItem.href}
											className={`
													block 
													py-2 
													pl-4 
													text-[15px] 
													
													hover:text-accent
													${
														compareCurrentLocation(subItem.href)
															? "text-accent font-extrabold"
															: "text-white font-medium "
													}
												`}
											onClick={toggleDrawer}
										>
											{subItem.name}
										</Link>
									))}
								</div>
							</div>
						))}
						<div className="pt-10">
							<Link
								to="/franchise"
								className="block py-1 text-[18px] font-medium text-secondary"
							>
								가맹문의
							</Link>
						</div>
						<div className="mt-4 flex justify-start items-center">
							<SocialLinks color="white" />
						</div>
					</div>

					<div className="p-4 flex justify-end items-center">
						<button
							onClick={toggleDrawer}
							className="text-white text-2xl font-medium hover:text-secondary focus:outline-none opacity-80"
							aria-label="닫기"
						>
							<svg
								className="h-[18px] w-[18px]"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="4"
									d="M4 4l16 16m-16 0l16-16"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			{/* 드로어 탑 */}
			<img
				src={drawerTop}
				alt="drawer top"
				className={`
						absolute 
						left-[-46px] 
						w-[335px]
						transition-all
						duration-300
						animate-drawer-shadow
						top-[18px]
					`}
			/>
		</div>
	);
};

const Navbar = () => {
	const { categories, getCategories } = useMenuStore();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [drawerPosition, setDrawerPosition] = useState(0);
	const buttonRef = useRef(null);
	const location = useLocation();
	const [openSubmenu, setOpenSubmenu] = useState(null);
	const scrollThreshold = 50;
	const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
	const timeoutRef = useRef(null);
	const [isSubPanelVisible, setIsSubPanelVisible] = useState(false);
	const scrollY = useScrollStore((state) => state.scrollY);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	const navItems = [
		{
			name: "브랜드",
			href: "/brand",
			subItems: [
				{ name: "브랜드 소개", href: "/brand#brand" },
				{ name: "원재료 소개", href: "/brand#ingredients" },
				{ name: "BI", href: "/brand#ci" },
			],
		},
		{
			name: "메뉴",
			href: "/menu",
			subItems: [
				{ name: "전체메뉴", href: "/menu/total" },
				...categories.map((category) => ({
					name: category.name,
					href: `/menu/category/${category.id}`,
				})),
			],
		},
		{
			name: "매장안내",
			href: "/locations",
			subItems: [{ name: "매장안내", href: "/locations" }],
		},
		{
			name: "새소식",
			href: "/news",
			subItems: [
				{ name: "프로모션", href: "/news/promotion" },
				{ name: "SNS", href: "/news/sns" },
			],
		},
		{
			name: "가맹문의",
			href: "/franchise",
			subItems: [{ name: "가맹문의", href: "/franchise" }],
		},
	];

	const updateDrawerPosition = () => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect();
			setDrawerPosition(rect.left);
		}
	};

	useEffect(() => {
		updateDrawerPosition();
		window.addEventListener("resize", updateDrawerPosition);
		return () => window.removeEventListener("resize", updateDrawerPosition);
	}, []);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
		document.body.style.overflow = isDrawerOpen ? "unset" : "hidden";
	};

	const handleMenuClick = (e, itemName) => {
		e.preventDefault();
		setOpenSubmenu(openSubmenu === itemName ? null : itemName);
	};

	const [logoSize, setLogoSize] = useState(
		scrollY > scrollThreshold ? "small" : "large"
	);

	useEffect(() => {
		const newLogoSize = scrollY > scrollThreshold ? "small" : "large";
		setLogoSize(newLogoSize);

		// navbar 높이를 CSS 변수로 설정
		document.documentElement.style.setProperty(
			"--nav-height",
			newLogoSize === "small" ? "75" : "110"
		);
	}, [scrollY]); // scrollY가 변경될 때마다 실행

	const handleMouseEnter = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		setIsMainMenuOpen(true);
	};

	const handleMouseLeave = () => {
		timeoutRef.current = setTimeout(() => {
			if (!isSubMenuOpen) {
				setIsMainMenuOpen(false);
			}
		}, 200); // 300ms 지연
	};

	const handleMainMenuEnter = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		setIsSubPanelVisible(true);
	};

	const handleMainMenuLeave = () => {
		timeoutRef.current = setTimeout(() => {
			setIsSubPanelVisible(false);
		}, 200); // 300ms 지연
	};

	useEffect(() => {
		// 드로어 닫기
		setIsDrawerOpen(false);
		// 서브메뉴 닫기
		setOpenSubmenu(null);
		// 메인메뉴 패널 닫기
		setIsMainMenuOpen(false);
		setIsSubMenuOpen(false);
		setIsSubPanelVisible(false);
		// body의 스크롤 상태 복구
		document.body.style.overflow = "unset";
	}, [location]);

	return (
		<>
			<nav className="bg-primary text-white sticky top-0 z-[200]">
				{/* 메인 메뉴 패널 - z-index 높임 */}
				<div
					className={`z-[200] max-w-7xl w-full mx-auto px-4 desktop:px-6 relative flex items-center transition-all duration-300 ease-in-out ${
						logoSize === "small"
							? "desktop:h-[75px] h-[65px]"
							: "desktop:h-[110px] h-[65px]"
					}`}
				>
					<div
						className="flex items-center justify-between  w-full h-full"
						// style={{ height: `${logoHeight * 2.5}px` }}
					>
						{/* 모바일에서는 로고를 중앙에 배치하기 위한 컨테이너 */}
						<div className="flex flex-row items-center desktop:items-center justify-between desktop:justify-between w-full h-full">
							{/* 햄버거 버튼 */}
							<button
								ref={buttonRef}
								onClick={toggleDrawer}
								aria-label="메인 메뉴"
								className={`desktop:hidden pr-2`} // 데스크톱에서 숨김
							>
								<svg
									className="h-10 w-10"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 6H20"
											stroke="#FFFFD4"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 12H20"
											stroke="#FF8C8D"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 18H20"
											stroke="#FFFFD4"
										/>
									</>
								</svg>
							</button>

							{/* 로고 - 모바일에서 중앙 정렬 */}
							<Link
								to="/"
								className="block transition-all duration-500 transform h-full flex items-center"
								aria-label="홈으로 이동"
							>
								<img
									src={mainTextLogo}
									alt="봉천동 매운 떡볶이"
									className={`transition-all duration-500 ease-in-out ${
										logoSize === "small"
											? "desktop:h-[32.6px] h-[32.6px]"
											: "desktop:h-[49.7px] h-[32.6px]"
									}`}
								/>
							</Link>

							{/* 모바일에서 우측 여백을 위한 더미 요소 */}
							<div className="w-12 h-12 desktop:hidden"></div>
						</div>

						{/* 데스크톱 메뉴 */}
						<div className="hidden desktop:block">
							<div
								className="flex items-center justify-end space-x-6"
								onMouseEnter={handleMainMenuEnter}
								onMouseLeave={handleMainMenuLeave}
							>
								{navItems.map((item) => (
									<div
										key={item.name}
										className="relative w-[100px] text-center"
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
									>
										<Link
											to={item.href}
											className={`
												relative
												inline-block
												text-center
												text-[18.53px] 
												
												font-noto 
												transition-all
												duration-500
												group
												${
													compareCurrentLocation(item.href)
														? "text-accent font-semibold"
														: "text-white hover:text-accent font-medium"
												}
											`}
											aria-label={item.name}
										>
											{item.name}
											<span
												className={`
													absolute 
													left-0 
													bottom-0 
													w-0 
													h-[3px] 
													bg-accent 
													transition-all 
													duration-500 
													group-hover:w-full
													${compareCurrentLocation(item.href) ? "w-full" : ""}
												`}
											/>
										</Link>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* 서브메뉴 패널 */}
				<div
					className={`
						absolute 
						w-full
						bg-primary 
						border-t
						border-secondary
						transition-all
						duration-300
						${
							logoSize === "small"
								? "desktop:top-[75px] top-[65px]"
								: "desktop:top-[110px] top-[65px]"
						}
						z-10
						${
							isSubPanelVisible
								? "opacity-100 translate-y-0 animate-border-grow"
								: "opacity-0 -translate-y-4 pointer-events-none border-t-0"
						}
					`}
					onMouseEnter={handleMainMenuEnter}
					onMouseLeave={handleMainMenuLeave}
					onClick={(e) => e.stopPropagation()}
				>
					<div className="max-w-7xl px-4 desktop:px-6 mx-auto flex items-center justify-between">
						<div className="flex items-center space-x-4">
							{/* 로고 */}
							<img
								src={mainTextLogo}
								alt="봉천동 매운 떡볶이"
								className="invisible"
							/>
						</div>

						{/* 서브 메뉴 */}
						<div className="desktop:block invisible desktop:visible">
							<div className="flex space-x-6">
								{navItems.map((item) => (
									<div
										key={item.name}
										className="relative py-4 w-[100px] text-center"
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
									>
										{item.subItems.length > 0 && (
											<ul className="space-y-3">
												{item.subItems.map((subItem) => (
													<li
														key={subItem.name}
														className={`text-center ${
															subItem.name === "가맹문의" ? "invisible" : ""
														}`}
													>
														<Link
															to={subItem.href}
															className={`
																relative
																inline-block
																text-center
																text-[16px]
																font-medium
																transition-all
																duration-500
																group
																${
																	compareCurrentLocation(subItem.href)
																		? "text-accent"
																		: "text-white hover:text-accent"
																}
															`}
														>
															{subItem.name}
															<span
																className={`
																	absolute 
																	left-0 
																	bottom-0 
																	w-0 
																	h-[1px] 
																	bg-accent 
																	transition-all 
																	duration-500 
																	group-hover:w-full
																	${compareCurrentLocation(subItem.href) ? "w-full" : ""}
																`}
															/>
														</Link>
													</li>
												))}
											</ul>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</nav>

			{/* 모바일 드로어 */}
			<DrawerPanel
				isDrawerOpen={isDrawerOpen}
				toggleDrawer={toggleDrawer}
				logoSize={logoSize}
				navItems={navItems}
				openSubmenu={openSubmenu}
				location={location}
				handleMenuClick={handleMenuClick}
			/>
		</>
	);
};

export default Navbar;
