import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

const fetchBanners = async () => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/banners/`);
		if (!response.ok) throw new Error("Failed to fetch banners");
		return await response.json();
	} catch (error) {
		console.error("Error fetching banners:", error);
		throw error;
	}
};

const useBannerStore = create((set) => ({
	// 상태
	banners: [],
	loading: false,
	error: null,

	// 액션
	getBanners: async () => {
		try {
			set({ loading: true, error: null });

			// 이미 배너 데이터가 있는지 확인
			const existingBanners = useBannerStore.getState().banners;
			if (existingBanners.length > 0) return existingBanners;

			// 없으면 새로 fetch
			const bannerData = await fetchBanners();

			set({
				banners: bannerData,
				loading: false,
			});

			return bannerData;
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
			throw error;
		}
	},

	// 배너 초기화
	resetBanners: () => {
		set({ banners: [], error: null });
	},
}));

export default useBannerStore;
