import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import useStoreStore from "../services/storeService";
import markerIcon from "../assets/images/marker.svg";
import storeHallIcon from "../assets/images/store_hall.svg";
import storePackingIcon from "../assets/images/store_packing.svg";
import storeDeliIcon from "../assets/images/store_deli.svg";
import storeHallOffIcon from "../assets/images/store_hall_off.svg";
import storePackingOffIcon from "../assets/images/store_packing_off.svg";
import storeDeliOffIcon from "../assets/images/store_deli_off.svg";
import storeHallBubbleIcon from "../assets/images/hall_bubble.svg";
import storePackingBubbleIcon from "../assets/images/packing_bubble.svg";
import storeDeliBubbleIcon from "../assets/images/delivery_bubble.svg";
import iconLogoPrimaryTransparent from "../assets/images/icon_logo_primary.svg";
import storeDetailBubble from "../assets/images/store_detail_bubble.svg";
import {
	searchAddresses,
	getCurrentLocation,
	searchDistricts,
	cities,
} from "../services/naverMapService";
import SearchFilter from "../components/store/SearchFilter";
import debounce from "lodash/debounce";
import React from "react";
import { Helmet } from "react-helmet-async";
import Scrollbar from "smooth-scrollbar";
const InfoWindowContent = ({ store, currentLocation, calculateDistance }) => {
	const content = document.createElement("div");
	content.className =
		"scale-[0.8] mobile:scale-100 translate-x-[43px] translate-y-[40px] mobile:translate-x-0 mobile:translate-y-0 origin-top-left";

	content.innerHTML = `
		<div class="relative">
			<img src="${storeDetailBubble}" alt="background" class="w-[453.18px] h-[209.34px]" />
			<div class="absolute top-[16px] left-[30px] right-[30px]">
				<div class="flex items-center justify-between">
					<div class="border-b-[1.5px] border-primary w-full flex flex-row items-center justify-between">
						<h3 class="text-primary text-[23.72px] font-bold">${store.name}</h3>
						<div class="bg-primary text-white text-[11.12px] font-noto font-medium px-1 py-0 rounded-full">
							${
								currentLocation
									? calculateDistance(
											currentLocation.lat,
											currentLocation.lng,
											store.lat,
											store.lng
									  )
									: ""
							}
						</div>
					</div>
				</div>
				<p class="text-gray700 mt-2 font-noto font-medium text-[16.4px]">${
					store.address
				}</p>
				<div class="flex justify-between items-end mt-7">
					<div class="text-[#9FA0A0] text-[15.3px] font-noto mb-2">
						<div><span class="font-bold text-primary">매장번호 </span>&nbsp;&nbsp;${
							store.phone
						}</div>
						<div><span class="font-bold text-primary">운영시간 </span>&nbsp;&nbsp;${
							store.operatingHours || "AM 10:00 ~ PM 12:00"
						}</div>
					</div>
					<div class="flex gap-2">
						<div class="relative group">
							${
								store.is_hall
									? `<img src="${storeHallIcon}" alt="매장식사" class="w-8 h-8" />`
									: `<img src="${storeHallOffIcon}" alt="매장식사" class="w-8 h-8" />`
							}
							<img src="${storeHallBubbleIcon}" alt="매장 식사" class="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]" />
						</div>
						<div class="relative group">
							${
								store.is_packing
									? `<img src="${storePackingIcon}" alt="포장" class="w-8 h-8" />`
									: `<img src="${storePackingOffIcon}" alt="포장" class="w-8 h-8" />`
							}
							<img src="${storePackingBubbleIcon}" alt="포장" class="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]" />
						</div>
						<div class="relative group">
							${
								store.is_delivery
									? `<img src="${storeDeliIcon}" alt="배달" class="w-8 h-8" />`
									: `<img src="${storeDeliOffIcon}" alt="배달" class="w-8 h-8" />`
							}
							<img src="${storeDeliBubbleIcon}" alt="배달" class="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]" />
						</div>
					</div>
				</div>
			</div>
		</div>
	`;

	return new Promise((resolve) => {
		const backgroundImg = content.querySelector("img");
		if (backgroundImg) {
			backgroundImg.onload = () => {
				resolve(content);
			};
			backgroundImg.onerror = () => {
				resolve(content);
			};
		} else {
			resolve(content);
		}
	});
};

const NaverMapSection = ({
	filteredStores,
	selectedStore,
	currentLocation,
	handleMarkerClick,
	mapRef,
	calculateDistance,
}) => {
	const [map, setMap] = useState(null);
	const [markers, setMarkers] = useState([]);
	const [infoWindow, setInfoWindow] = useState(null);
	const [isScriptLoaded, setIsScriptLoaded] = useState(false);

	// 스크립트 로드 함수
	useEffect(() => {
		if (!window.naver) {
			const script = document.createElement("script");
			script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.REACT_APP_NAVER_MAP_CLIENT_ID}`;
			script.async = true;
			script.onload = () => setIsScriptLoaded(true);
			document.head.appendChild(script);

			return () => {
				document.head.removeChild(script);
			};
		} else {
			setIsScriptLoaded(true);
		}
	}, []);

	// 지도 초기화
	useEffect(() => {
		if (!isScriptLoaded || map) return;

		const mapDiv = document.getElementById("store-map");
		if (!mapDiv) {
			console.error("지도 컨테이너를 찾을 수 없습니다.");
			return;
		}

		const mapOptions = {
			center: new window.naver.maps.LatLng(
				selectedStore?.lat || 37.5665,
				selectedStore?.lng || 126.978
			),
			zoom: 13,
			scaleControl: false,
			logoControl: false,
			mapDataControl: false,
			zoomControl: true,
			zoomControlOptions: {
				position: window.naver.maps.Position.LEFT_BOTTOM,
				style: window.naver.maps.ZoomControlStyle.SMALL,
				anchorSkew: true,
				buttons: ["+", "-"],
			},
			minZoom: 6,
			maxZoom: 21,
		};

		try {
			const newMap = new window.naver.maps.Map(mapDiv, mapOptions);
			setMap(newMap);
			if (mapRef) {
				mapRef.current = newMap;
			}

			// InfoWindow 초기화
			const newInfoWindow = new window.naver.maps.InfoWindow({
				content: "",
				backgroundColor: "transparent",
				borderColor: "transparent",
				borderWidth: 0,
				disableAnchor: true,
			});
			setInfoWindow(newInfoWindow);
		} catch (error) {
			console.error("지도 초기화 실패:", error);
		}
	}, [isScriptLoaded, map, mapRef, selectedStore]);

	// 컴포넌트 언마운트 시 정리
	useEffect(() => {
		return () => {
			if (map) {
				map.destroy();
			}
		};
	}, [map]);

	// 마커 생성 및 관리
	useEffect(() => {
		if (!map) return;

		// 기존 마커 제거
		markers.forEach((marker) => marker.setMap(null));

		// 새로운 마커 생성
		const newMarkers = filteredStores.map((store) => {
			const isSelected = selectedStore?.id === store.id;
			const selectedWidth = 47.1;
			const selectedHeight = (30.96 * 47.1) / 20.1;

			const marker = new window.naver.maps.Marker({
				position: new window.naver.maps.LatLng(store.lat, store.lng),
				map: map,
				icon: {
					url: isSelected ? iconLogoPrimaryTransparent : markerIcon,
					size: new window.naver.maps.Size(
						isSelected ? selectedWidth : 20.1,
						isSelected ? selectedHeight : 30.96
					),
					scaledSize: new window.naver.maps.Size(
						isSelected ? selectedWidth : 20.1,
						isSelected ? selectedHeight : 30.96
					),
					origin: new window.naver.maps.Point(0, 0),
					anchor: new window.naver.maps.Point(
						isSelected ? selectedWidth / 2 : 10.05,
						isSelected ? selectedHeight : 30.96
					),
				},
				animation: isSelected ? window.naver.maps.Animation.BOUNCE : null,
			});

			// 마커 클릭 이벤트
			window.naver.maps.Event.addListener(marker, "click", () => {
				handleMarkerClick(store);
			});

			return marker;
		});

		setMarkers(newMarkers);

		return () => {
			newMarkers.forEach((marker) => marker.setMap(null));
		};
	}, [map, filteredStores, selectedStore]);

	// InfoWindow 업데이트
	useEffect(() => {
		if (!map || !infoWindow) return;

		if (!selectedStore) {
			infoWindow.close();
			return;
		}

		const updateInfoWindow = async () => {
			const contentElement = await InfoWindowContent({
				store: selectedStore,
				currentLocation,
				calculateDistance,
			});

			const position = new window.naver.maps.LatLng(
				selectedStore.lat,
				selectedStore.lng
			);

			// 선택된 마커의 크기에 따라 오프셋 동적 계산
			const isSelected = true; // 선택된 마커이므로 true
			const selectedHeight = (30.96 * 47.1) / 20.1 + 5;

			// 마커의 높이를 고려한 오프셋 계산
			const markerHeight = isSelected ? selectedHeight : 30.96;
			const verticalOffset = -markerHeight;

			infoWindow.setOptions({
				content: contentElement,
				position: position,
				pixelOffset: new window.naver.maps.Point(0, verticalOffset),
			});

			infoWindow.open(map);
		};

		updateInfoWindow();

		return () => {
			infoWindow.close();
		};
	}, [map, infoWindow, selectedStore, currentLocation]);

	return (
		<div className="h-full w-full bg-white">
			<div id="store-map" className="w-full h-full" aria-label="네이버 지도" />
		</div>
	);
};

// 매장 제목 컴포넌트 분리
const StoreTitle = React.memo(({ name, isSelected }) => {
	return (
		<h3
			className={`font-bold text-[20.29px] ${
				isSelected ? "text-primary" : "text-primaryText"
			}`}
		>
			{name}
		</h3>
	);
});

// 개별 매장 카드 컴포넌트
const StoreCard = React.memo(
	({
		store,
		selectedStoreId,
		currentLocation,
		calculateDistance,
		onStoreClick,
	}) => {
		return (
			<div
				className="font-noto font-medium border-b border-gray-200 last:border-0 pb-4 cursor-pointer"
				onClick={() => onStoreClick(store)}
			>
				<div className="flex flex-row justify-between items-end">
					<div className="flex items-start gap-1">
						<StoreTitle
							name={store.name}
							isSelected={selectedStoreId === store.id}
						/>
					</div>
					<p className="text-[13.16px] text-[#9FA0A0]">
						{currentLocation
							? calculateDistance(
									currentLocation.lat,
									currentLocation.lng,
									store.lat,
									store.lng
							  )
							: ""}
					</p>
				</div>
				<p className="text-[#9FA0A0] text-[16.66px] mt-1 mb-[22px]">
					{store.address}
				</p>
				<div className="flex flex-row gap-2 items-end justify-between">
					<p className="text-gray600 text-[16.57px] font-medium">
						{store.phone}
					</p>
					<div className="flex flex-row gap-2">
						<div className="relative group">
							<img
								src={store.is_hall ? storeHallIcon : storeHallOffIcon}
								alt="storeHall"
								className="w-[30.96px] h-[30.96px]"
							/>
							<img
								src={storeHallBubbleIcon}
								alt="매장 식사"
								className="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]"
							/>
						</div>
						<div className="relative group">
							<img
								src={store.is_packing ? storePackingIcon : storePackingOffIcon}
								alt="storePacking"
								className="w-[30.96px] h-[30.96px]"
							/>
							<img
								src={storePackingBubbleIcon}
								alt="포장"
								className="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]"
							/>
						</div>
						<div className="relative group">
							<img
								src={store.is_delivery ? storeDeliIcon : storeDeliOffIcon}
								alt="storeDeli"
								className="w-[30.96px] h-[30.96px]"
							/>
							<img
								src={storeDeliBubbleIcon}
								alt="배달"
								className="absolute -top-7 left-1/2 -translate-x-1/2 w-[120px] opacity-0 group-hover:opacity-100 transition-opacity duration-200 scale-[1.5]"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		// 선택된 매장 ID가 변경되었을 때만 리렌더링
		return (
			prevProps.selectedStoreId === nextProps.selectedStoreId &&
			prevProps.currentLocation === nextProps.currentLocation
		);
	}
);

// StoreList 컴포넌트 수정
const StoreList = React.memo(
	({
		filteredStores,
		selectedStore,
		currentLocation,
		calculateDistance,
		handleMarkerClick,
	}) => {
		const scrollbarRef = useRef(null);

		useEffect(() => {
			// smooth-scrollbar 초기화
			const scrollContainer = document.querySelector("#store-scrollbar");
			if (scrollContainer) {
				scrollbarRef.current = Scrollbar.init(scrollContainer, {
					damping: 0.1,
					thumbMinSize: 20,
					renderByPixels: true,
					alwaysShowTracks: false,
					continuousScrolling: true,
				});

				// 페이지 변경 시 스크롤 위치 초기화
				scrollbarRef.current.setPosition(0, 0);

				return () => {
					if (scrollbarRef.current) {
						scrollbarRef.current.destroy();
						scrollbarRef.current = null;
					}
				};
			}
		}, []);

		return (
			<div
				className="h-full overflow-y-auto scrollbar-hide"
				id="store-scrollbar"
			>
				<div className="space-y-4 py-[22.2px] px-[32.5px]">
					{filteredStores.map((store) => (
						<StoreCard
							key={store.id}
							store={store}
							selectedStoreId={selectedStore?.id}
							currentLocation={currentLocation}
							calculateDistance={calculateDistance}
							onStoreClick={handleMarkerClick}
						/>
					))}
				</div>
			</div>
		);
	}
);

const StorePage = () => {
	// Zustand store에서 필요한 상태와 액션 가져오기
	const { regions, loading, error, getRegions } = useStoreStore();

	// 로컬 태 관리
	const [selectedRegion, setSelectedRegion] = useState("전체");
	const [searchKeyword, setSearchKeyword] = useState("");
	const [selectedStore, setSelectedStore] = useState(null);

	// 새로운 상태 추가
	const [searchMode, setSearchMode] = useState("location"); // 'location' 또는 'search'
	const [selectedCity, setSelectedCity] = useState("");
	const [selectedDistrict, setSelectedDistrict] = useState("");
	const [currentLocation, setCurrentLocation] = useState(null);
	const [searchResults, setSearchResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [districts, setDistricts] = useState([]);
	const [searchInput, setSearchInput] = useState(""); // 입력값을 한 새로운 상태

	// 지도 참조를 위한 ref 추가
	const mapRef = useRef(null);

	useEffect(() => {
		getRegions();
		window.scrollTo(0, 0); // 페이지 최상단으로 스크롤
	}, [getRegions]);

	// 모든 장 데이터를 하나의 배열로 변환
	const allStores = regions.flatMap((region) =>
		region.stores.map((store) => ({
			...store,
			regionName: region.name,
		}))
	);

	// 디바운스된 검색 설정
	const debouncedSetSearchKeyword = useCallback(
		debounce((value) => {
			setSearchKeyword(value);
			setIsSearching(false);
		}, 300),
		[]
	);

	// 키 입력 이벤트 핸들
	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === "Enter") {
				e.preventDefault();
				setIsSearching(true);
				debouncedSetSearchKeyword(e.target.value);
			}
		},
		[debouncedSetSearchKeyword]
	);

	// 입력값 변경시 자동 검색
	useEffect(() => {
		if (searchMode === "search") {
			setIsSearching(true);
			debouncedSetSearchKeyword(searchInput);
		}
	}, [searchInput, searchMode, debouncedSetSearchKeyword]);

	// 헬퍼 함수들을 먼저 선언
	const deg2rad = (deg) => {
		return deg * (Math.PI / 180);
	};

	const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
		const R = 6371; // 지구의 반경 (km)
		const dLat = deg2rad(lat2 - lat1);
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) *
				Math.cos(deg2rad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;
	};

	// 필터링된 매장 목록
	const filteredStores = useMemo(() => {
		let stores = [...allStores];

		if (searchMode === "search") {
			// 지역 필터링
			if (selectedCity) {
				stores = stores.filter((store) => {
					const storeAddress = store.address;
					if (selectedDistrict) {
						// 시/도와 구/군이 모두 선택된 경우
						return (
							storeAddress.includes(selectedCity) &&
							storeAddress.includes(selectedDistrict)
						);
					}
					// 시/도만 선택된 경우
					return storeAddress.includes(selectedCity);
				});
			}

			// 검색어로 필터링
			if (searchKeyword) {
				stores = stores.filter((store) =>
					store.name.toLowerCase().includes(searchKeyword.toLowerCase())
				);
			}
		}

		// 현재 위치 기반 정렬 (내 주변에서 찾기 모드)
		if (currentLocation && searchMode === "location") {
			stores.sort((a, b) => {
				const distanceA = getDistanceFromLatLonInKm(
					currentLocation.lat,
					currentLocation.lng,
					a.lat,
					a.lng
				);
				const distanceB = getDistanceFromLatLonInKm(
					currentLocation.lat,
					currentLocation.lng,
					b.lat,
					b.lng
				);
				return distanceA - distanceB;
			});
		}

		return stores;
	}, [
		allStores,
		searchMode,
		searchKeyword,
		currentLocation,
		selectedCity,
		selectedDistrict,
	]);

	// 마커 선택 해제
	const handleMarkerDeselect = () => {
		setSelectedStore(null);
	};

	// 마커 클릭 이벤트 핸들러
	const handleMarkerClick = (store) => {
		// 이미 선택된 매장을 다시 클릭한 경우 선택 해제
		if (selectedStore?.id === store.id) {
			handleMarkerDeselect();
			return;
		}

		// 새로운 매장 선택
		setSelectedStore(store);
		// 선택된 매장의 위치로 지도 이동
		moveToLocation(store.lat, store.lng);
	};

	// 지도 이동
	const moveToLocation = (lat, lng, zoom = 15) => {
		if (mapRef.current) {
			const center = new window.naver.maps.LatLng(lat, lng);
			mapRef.current.setCenter(center);
			mapRef.current.setZoom(zoom);
		}
	};

	// 현재 위치로 이동
	const handleLocationSearch = async () => {
		try {
			const location = await getCurrentLocation();
			setCurrentLocation(location);
			moveToLocation(location.lat, location.lng);
		} catch (error) {
			console.error("위치 검색 실패:", error);
			alert("현재 위치를 가져올 수 없습니다.");
		}
	};

	// 시/도 선택 시 구/군 목록 가져오기
	useEffect(() => {
		const fetchDistricts = async () => {
			if (selectedCity) {
				const districtList = await searchDistricts(selectedCity);
				setDistricts(districtList);
			} else {
				// selectedCity가 없을 때 (선택 해제됐을 때)
				setDistricts([]);
				setSelectedDistrict(""); // 구/군 선택 초기화
			}
		};

		fetchDistricts();
	}, [selectedCity]);

	// 지역 선택 시 지도 이동
	const handleRegionSelect = async () => {
		if (selectedCity) {
			const searchQuery = selectedDistrict
				? `${selectedCity} ${selectedDistrict}`
				: selectedCity;

			try {
				const results = await searchAddresses(searchQuery);
				if (results.length > 0) {
					const { y: lat, x: lng } = results[0];
					moveToLocation(lat, lng, selectedDistrict ? 14 : 11);
				}
			} catch (error) {
				console.error("지역 검색 실패:", error);
			}
		} else {
			// 도/시가 선택 해제된 경우
			if (currentLocation) {
				// 현재 위치가 있으면 현재 위치로 이동
				moveToLocation(currentLocation.lat, currentLocation.lng, 13);
			} else {
				// 현재 위치가 없으면 기본 위치(서울)로 이동
				moveToLocation(37.5665, 126.978, 13);
			}
		}
	};

	// 지역 선택 변경 시 지도 업데이트
	useEffect(() => {
		handleRegionSelect();
	}, [selectedCity, selectedDistrict]);

	// 두 지점 간의 거리를 계산하는 함수 추가
	const calculateDistance = (lat1, lon1, lat2, lon2) => {
		const R = 6371; // 지구의 반경 (km)
		const dLat = ((lat2 - lat1) * Math.PI) / 180;
		const dLon = ((lon2 - lon1) * Math.PI) / 180;
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos((lat1 * Math.PI) / 180) *
				Math.cos((lat2 * Math.PI) / 180) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = R * c;

		// 1km 미만일 경우 m 단위로 이동시
		if (distance < 1) {
			return `${Math.round(distance * 1000)}m`;
		}
		// 1km 이상일 경우 소수점 첫째 자리까지 표시
		return `${distance.toFixed(1)}km`;
	};

	// 페이지 진입 시 위치 정보 초기화
	useEffect(() => {
		const initializeLocation = async () => {
			try {
				const location = await getCurrentLocation();
				setCurrentLocation(location);
				moveToLocation(location.lat, location.lng);
			} catch (error) {
				console.error("현재 위치를 가져올 수 없습니다:", error);
			}
		};

		initializeLocation();
	}, []); // 컴포넌트 마운트 시 한 번만 실행

	// SEO 메타 데이터를 생성하는 함수
	const getSEOMetadata = () => {
		if (searchMode === "search") {
			const locationText = selectedCity
				? selectedDistrict
					? `${selectedCity} ${selectedDistrict}`
					: selectedCity
				: "";

			return {
				title: locationText
					? `${locationText} 매장 | 봉천동 매운 떡볶이`
					: "매장안내 | 봉천동 매운 떡볶이",
				description: locationText
					? `${locationText}에 있는 봉천동 매운 떡볶이 매장을 찾아보세요. 매장 위치, 연락처, 영업시간 등 상세 정보를 제공합니다.`
					: "봉천동 매운 떡볶이의 전국 매장을 찾아보세요. 내 주변 매장 찾기, 지역별 매장 검색이 가능합니다.",
				keywords: `봉천동 매운 떡볶이 ${
					locationText ? locationText + " " : ""
				}매장, 매장찾기, 매장위치, 매장정보`,
				url: "https://bongmaetteok.co.kr/locations",
			};
		}

		// 내 주변 매장 찾기 모드
		return {
			title: "내 주변 매장 | 봉천동 매운 떡볶이",
			description:
				"현재 위치 주변의 봉천동 매운 떡볶이 매장을 찾아보세요. 가까운 매장의 위치와 상세 정보를 확인할 수 있습니다.",
			keywords:
				"봉천동 매운 떡볶이 주변 매장, 근처 매장, 내 위치 주변 매장, 매장찾기",
			url: "https://bongmaetteok.co.kr/locations",
		};
	};

	const seoData = getSEOMetadata();

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<>
			<Helmet>
				<title>{seoData.title}</title>
				<meta name="description" content={seoData.description} />
				<meta name="keywords" content={seoData.keywords} />
				<link rel="canonical" href={seoData.url} />
			</Helmet>
			<div className="h-screen">
				<div className="flex flex-col-reverse tablet:flex-row h-full">
					{/* 매장 리스트 섹션 */}
					<div className="h-[60%] tablet:h-full tablet:w-[450.4px] tablet:flex-shrink-0 flex flex-col bg-white">
						<div className="flex-shrink-0">
							<SearchFilter
								searchMode={searchMode}
								setSearchMode={setSearchMode}
								searchInput={searchInput}
								setSearchInput={setSearchInput}
								handleKeyDown={handleKeyDown}
								handleLocationSearch={handleLocationSearch}
								handleMarkerClick={handleMarkerClick}
								handleMarkerDeselect={handleMarkerDeselect}
								isSearching={isSearching}
								selectedCity={selectedCity}
								setSelectedCity={setSelectedCity}
								selectedDistrict={selectedDistrict}
								setSelectedDistrict={setSelectedDistrict}
								districts={districts}
								cities={cities}
							/>
						</div>
						<div className="flex-1 min-h-0 overflow-y-auto">
							<StoreList
								filteredStores={filteredStores}
								selectedStore={selectedStore}
								currentLocation={currentLocation}
								calculateDistance={calculateDistance}
								handleMarkerClick={handleMarkerClick}
							/>
						</div>
					</div>

					{/* 지도 섹션 */}
					<div className="h-[40vh] tablet:h-full tablet:flex-1">
						<div className="h-full w-full">
							<NaverMapSection
								filteredStores={filteredStores}
								selectedStore={selectedStore}
								currentLocation={currentLocation}
								handleMarkerClick={handleMarkerClick}
								mapRef={mapRef}
								calculateDistance={calculateDistance}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StorePage;
