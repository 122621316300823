import NewsCard from "../news/NewsCard";
import SkeletonNewsCard from "./SkeletonNewsCard";
import { useState, useEffect, forwardRef, useRef } from "react";

const PromotionGrid = forwardRef(({ posts, isLoading, onPostClick }, ref) => {
	const gridRef = useRef(null);
	const skeletonArray = Array(9).fill(null);

	const [selectedStatus, setSelectedStatus] = useState("전체");

	useEffect(() => {
		setSelectedStatus("전체");
	}, [posts]);

	const handleTabChange = (status) => {
		setSelectedStatus(status);
	};

	const getTabStyle = (status) => {
		return selectedStatus === status
			? "text-white  font-bold bg-primary rounded-full px-[20px] py-[4.5px]"
			: "text-[#686767] font-medium px-[20px] py-[4.5px]";
	};

	return (
		<div
			ref={gridRef}
			className="flex flex-col mb-[76px] desktop:mb-[200px] px-3 desktop:px-0"
		>
			<div className="w-full mb-[38px] desktop:mb-[47px] flex flex-row items-center justify-center desktop:justify-start font-pretendard gap-1 desktop:gap-3 text-[14px] desktop:text-[17px] pl-4">
				<div
					className={`cursor-pointer ${getTabStyle("전체")}`}
					onClick={() => handleTabChange("전체")}
				>
					전체
				</div>
				<div
					className={`cursor-pointer ${getTabStyle("진행중")}`}
					onClick={() => handleTabChange("진행중")}
				>
					진행중 이벤트
				</div>
				<div
					className={`cursor-pointer ${getTabStyle("종료")}`}
					onClick={() => handleTabChange("종료")}
				>
					종료된 이벤트
				</div>
			</div>
			<div className="grid grid-cols-1 mobile:grid-cols-2 tablet:grid-cols-3 gap-[15.57px] mobile:gap-[15.57px] tablet:gap-[5.57px] desktop:gap-[35.57px] touch-pan-y touch-manipulation">
				{isLoading
					? skeletonArray.map((_, index) => (
							<SkeletonNewsCard key={`skeleton-${index}`} />
					  ))
					: posts
							.filter((post) =>
								selectedStatus === "전체"
									? true
									: post.status === selectedStatus
							)
							.sort((a, b) => {
								if (a.status === "종료" && b.status !== "종료") return 1;
								if (a.status !== "종료" && b.status === "종료") return -1;

								return new Date(b.createdAt) - new Date(a.createdAt);
							})
							.map((post, index) => (
								<NewsCard
									ref={index === 0 ? ref : null}
									key={post.id}
									post={post}
									onClick={() => onPostClick(post)}
								/>
							))}
			</div>
		</div>
	);
});

PromotionGrid.displayName = "PromotionGrid";

export default PromotionGrid;
