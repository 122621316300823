import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import useCopyStore from "../../services/copyService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
const DetailButton = ({ href, className }) => {
	return (
		<Link to={href} className={`${className}`}>
			<div
				className="text-[12px] mt-[37.76px] px-12 tablet:py-2 py-[6.5px] rounded-full font-bold
						relative bg-primary text-white overflow-hidden
							transition-all duration-300 ease-in-out
							hover:bg-lightgray hover:text-primary
							before:content-['']
							before:absolute before:bottom-[-100%] before:left-[-100%]
							before:w-[300%] before:h-[300%]
							before:bg-primary
							before:rotate-45
							before:transition-transform before:duration-500 before:ease-out
							hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
							[&>p]:relative [&>p]:z-10
							cursor-pointer"
				role="button"
				aria-label="자세히 보기"
				tabIndex="0"
			>
				<p className="tablet:font-pretendard font:urbane tablet:font-normal font-semibold tablet:text-[18.43px] text-[12px]">
					자세히보기
				</p>
			</div>
		</Link>
	);
};

const Card = ({
	href,
	inView,
	bannerImage,
	bannerImageGray,
	firstCopy,
	secondCopy,
	thirdCopy,
}) => {
	return (
		<div className="flex flex-col justify-center items-center">
			<div className="group relative aspect-[961/613] shadow-lg mb-[-20px] overflow-hidden">
				<img
					src={bannerImage}
					alt="브랜드 스토리 이미지"
					className="w-full h-full object-cover transition-all duration-300 group-hover:scale-110 opacity-100"
				/>
				<div className="absolute inset-0 w-full h-full bg-black opacity-0 group-hover:opacity-[0.2]"></div>
				{/* <img
					src={bannerImageGray}
					alt="브랜드 스토리 이미지"
					className="absolute inset-0 w-full h-full object-cover transition-all duration-100 group-hover:scale-110 invisible group-hover:visible group-hover:opacity-100"
				/> */}
			</div>
			<div className="absolute bottom-[40px] left-[-20px] desktop:bottom-12 desktop:left-auto desktop:right-12 flex flex-col justify-end overflow-hidden scale-[0.7] desktop:scale-100">
				<h3
					className={`text-white font-pretendard text-[30px] font-bold mb-[4.4px] leading-[36px] whitespace-nowrap ${
						inView ? "animate-fade-slide-left-to-right" : "opacity-50"
					}`}
				>
					{firstCopy}
				</h3>

				<h1
					className={`font-mont text-white text-[50px] font-black mb-[7.32px] leading-[64px] whitespace-nowrap ${
						inView ? "animate-fade-slide-left-to-right" : "opacity-50"
					}`}
				>
					{secondCopy}
				</h1>
				<p
					className={` font-pretendard text-white text-[20px] leading-[24px] whitespace-nowrap ${
						inView ? "animate-fade-slide-left-to-right" : "opacity-50"
					}`}
				>
					{thirdCopy}
				</p>
				<div
					className={`flex justify-start ${
						inView ? "animate-fade-slide-left-to-right" : "opacity-50"
					}`}
				>
					<DetailButton href={href} className="hidden desktop:block" />
				</div>
			</div>
			{/* 자세히 보기 버튼 */}
			<DetailButton href={href} className="desktop:hidden" />
		</div>
	);
};

const FavoriteSection = () => {
	const { copies, getCopy } = useCopyStore();
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	useEffect(() => {
		const loadCopy = async () => {
			try {
				await getCopy("PAGE-002");
				await getCopy("PAGE-003");
				await getCopy("PAGE-005");
				await getCopy("PAGE-006");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
	}, []);
	const about1 = copies["PAGE-002"]?.media;
	const about2 = copies["PAGE-003"]?.media;
	const about1Gray = copies["PAGE-005"]?.media;
	const about2Gray = copies["PAGE-006"]?.media;

	return (
		<section ref={sectionRef} className="pt-[85px] tablet:pt-[117.48px]">
			<div className="mx-auto overflow-hidden">
				<Swiper
					breakpoints={{
						350: {
							slidesPerView: 1.2,
							centeredSlides: true,
							spaceBetween: 20,
							loop: true,
							allowTouchMove: true,
						},

						1024: {
							slidesPerView: 2,
							centeredSlides: false,
							spaceBetween: 0,
							loop: false,
							allowTouchMove: false,
						},
						2000: {
							slidesPerView: 2,
							centeredSlides: false,
							spaceBetween: 0,
							loop: false,
							allowTouchMove: false,
						},
					}}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
						waitForTransition: true,
						pauseOnMouseEnter: true,
						initialDelay: 4000,
					}}
					slidesPerView={2}
					centeredSlides={false}
					spaceBetween={0}
					loop={false}
					className={`${inView ? "animate-fade-slide-left-to-right" : ""}`}
				>
					<SwiperSlide key={1}>
						<Card
							href="/brand"
							inView={inView}
							bannerImage={about1}
							bannerImageGray={about1Gray}
							firstCopy="브랜드 스토리"
							secondCopy="BRAND STORY"
							thirdCopy="최고의 순간을 봉매떡과 함께 즐겨보세요."
						/>
					</SwiperSlide>
					<SwiperSlide key={2}>
						<Card
							href="/franchise"
							inView={inView}
							bannerImage={about2}
							bannerImageGray={about2Gray}
							firstCopy="창업문의"
							secondCopy="FRANCHISE"
							thirdCopy="봉매떡 매장유형과 가맹절차를 알아보세요."
						/>
					</SwiperSlide>
					<SwiperSlide key={3}>
						<Card
							href="/brand"
							inView={inView}
							bannerImage={about1}
							bannerImageGray={about1Gray}
							firstCopy="브랜드 스토리"
							secondCopy="BRAND STORY"
							thirdCopy="최고의 순간을 봉매떡과 함께 즐겨보세요."
						/>
					</SwiperSlide>
					<SwiperSlide key={4}>
						<Card
							href="/franchise"
							inView={inView}
							bannerImage={about2}
							bannerImageGray={about2Gray}
							firstCopy="창업문의"
							secondCopy="FRANCHISE"
							thirdCopy="봉매떡 매장유형과 가맹절차를 알아보세요."
						/>
					</SwiperSlide>
				</Swiper>
			</div>
		</section>
	);
};

export default FavoriteSection;
