import { useState, useEffect } from "react";

export const useMediaQuery = (query) => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		setMatches(mediaQuery.matches);

		const handler = (event) => setMatches(event.matches);
		mediaQuery.addEventListener("change", handler);

		return () => mediaQuery.removeEventListener("change", handler);
	}, [query]);

	return matches;
};

// 미디어 쿼리 breakpoint 상수
export const BREAKPOINTS = {
	MOBILE: "(max-width: 478px)",
	MOBTABLET: "(max-width: 678px)",
	TABLET: "(max-width: 768px)",
	BIGTABLET: "(max-width: 860px)",
	DESKTOP: "(max-width: 1024px)",
	WIDE: "(max-width: 1275px)",
};
