import { forwardRef } from "react";
import ProgressiveImage from "react-progressive-graceful-image";

const NewsCard = forwardRef(({ post, onClick }, ref) => {
	return (
		<div
			ref={ref}
			className="relative cursor-pointer transition-all duration-300 ease-out hover:opacity-90 group mb-[20.17px] desktop:mb-[35px] border-[1px] border-gray-200 group overflow-hidden w-full"
			onClick={onClick}
			role="button"
			tabIndex={0}
		>
			<div className="flex flex-col w-full">
				{/* 이미지 컨테이너 */}
				<div className="aspect-[1/1] relative overflow-hidden">
					<ProgressiveImage
						src={post.banner_image || post.image}
						placeholder="/placeholder.jpg"
						error="/error.jpg"
						cache
					>
						{(src, loading) => (
							<div className="w-full h-full">
								{loading ? (
									<div className="w-full aspect-[1/1] animate-pulse">
										<div className="w-full h-full bg-gray-200 rounded-lg" />
									</div>
								) : (
									<img
										src={src}
										alt={post.title}
										draggable="false"
										className="select-none touch-pan-y w-full h-full object-cover transition-transform duration-300 ease-out group-hover:scale-110"
									/>
								)}
							</div>
						)}
					</ProgressiveImage>
					{post.youtube_url && (
						<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
							<div className="absolute w-[87.22px] h-[87.22px] object-cover">
								<svg
									width="89"
									height="89"
									viewBox="0 0 89 89"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M44.1973 85.2249C66.8906 85.2249 85.2871 66.8284 85.2871 44.135C85.2871 21.4416 66.8906 3.04492 44.1973 3.04492C21.5039 3.04492 3.10742 21.4416 3.10742 44.135C3.10742 66.8284 21.5039 85.2249 44.1973 85.2249Z"
										fill="#D2191A"
									/>
									<path
										d="M44.1978 87.7454C68.2829 87.7454 87.8076 68.2206 87.8076 44.1355C87.8076 20.0504 68.2829 0.525391 44.1978 0.525391C20.1126 0.525391 0.587891 20.0504 0.587891 44.1355C0.587891 68.2206 20.1126 87.7454 44.1978 87.7454Z"
										stroke="#D2191A"
										strokeWidth="0.75"
										strokeMiterlimit="10"
									/>
									<path
										d="M68.7583 44.1353L27.6582 20.4053V67.8552L68.7583 44.1353Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
					)}
					{/* 종료된 이벤트 커버 */}
					{post.status === "종료" && (
						<div className="absolute top-0 left-0 w-full h-full bg-black opacity-80 text-white text-center flex items-center justify-center font-noto text-[20.3px]">
							종료된 이벤트 입니다.
						</div>
					)}
				</div>

				{/* 컨텐츠 영역 */}
				<div className="flex flex-col py-3 px-4 group-hover:bg-primary transition-colors duration-300 h-[111px] desktop:h-[116px] justify-between">
					<b className="text-secondaryText text-base lg:text-lg font-bold font-noto leading-[1.6] group-hover:text-white transition-colors duration-300 line-clamp-2">
						{post.title}
					</b>
					<div className="text-[#8B8B8B] text-base lg:text-lg font-medium font-noto mt-2 group-hover:text-white transition-colors duration-300">
						{post.launch_date} ~ {post.end_date}
					</div>
				</div>
			</div>
		</div>
	);
});

NewsCard.displayName = "NewsCard";

export default NewsCard;
