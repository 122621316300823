const SkeletonInstagramCard = () => {
	return (
		<div className="animate-pulse">
			<div className="w-[285px] flex-shrink-0">
				{/* 이미지 스켈레톤 */}
				<div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg"></div>

				{/* 하단 텍스트 영역 스켈레톤 */}
				<div className="flex justify-between items-center h-[45.58px] px-[11.58px]">
					<div className="h-4 w-32 bg-gray-200 rounded"></div>
					<div className="h-4 w-16 bg-gray-200 rounded"></div>
				</div>
			</div>
		</div>
	);
};

export default SkeletonInstagramCard;
