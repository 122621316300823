import React, { useCallback } from "react";
import searchIcon from "../../assets/images/search_icon.svg";

const SearchInput = React.memo(
	({ searchInput, setSearchInput, handleKeyDown, isSearching }) => {
		const handleInputChange = useCallback(
			(e) => {
				setSearchInput(e.target.value);
			},
			[setSearchInput]
		);

		return (
			<div className="relative">
				<input
					type="text"
					placeholder="매장명을 입력하세요"
					value={searchInput}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					className="w-full px-4 py-3 border-b border-[#E6E6E6] focus:outline-none focus:border-b-2 focus:border-primary placeholder:font-noto placeholder:text-gray700 placeholder:font-medium placeholder:text-[14px]"
				/>
				{isSearching && (
					<div className="absolute right-6 top-1/2 transform -translate-y-1/2">
						<div className="animate-spin rounded-full h-4 w-4 border-b-2 border-red-600"></div>
					</div>
				)}
				{!isSearching && (
					<div className="absolute right-6 top-1/2 transform -translate-y-1/2">
						<img src={searchIcon} alt="search" className="w-4 h-4" />
					</div>
				)}
			</div>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.searchInput === nextProps.searchInput &&
			prevProps.isSearching === nextProps.isSearching
		);
	}
);

SearchInput.displayName = "SearchInput";

export default SearchInput;
