import { useInView } from "react-intersection-observer";
import section2Logo1 from "../../assets/images/logo1.webp";
import section2Logo2 from "../../assets/images/logo2.webp";
import section1SlogonBgImage from "../../assets/images/slogan_bg.svg";
import useCopyStore from "../../services/copyService";
import { TypeAnimation } from "react-type-animation";

// HTML 문자열에서 텍스트만 추출하는 헬퍼 함수
const stripHtml = (html) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, "text/html");
	const paragraphs = doc.getElementsByTagName("p");
	if (paragraphs.length > 0) {
		return Array.from(paragraphs)
			.map((p) => p.textContent)
			.join(" ");
	}
	return "";
};

const BrandIdentitySection = () => {
	const { ref: section1Ref, inView: section1InView } = useInView({
		threshold: 0.1,
		triggerOnce: false,
	});
	const { ref: section2Ref, inView: section2InView } = useInView({
		threshold: 0.2,
		triggerOnce: false,
	});
	const { ref: section3Ref, inView: section3InView } = useInView({
		threshold: 0.2,
		triggerOnce: false,
	});

	const { copies } = useCopyStore();

	const section1Title = copies["PAGE-040"]?.content;
	const section1Copy = copies["PAGE-041"]?.content;
	const section1SlogonText = copies["PAGE-042"]?.content;

	const section2Title = copies["PAGE-043"]?.content;
	const section2Copy = copies["PAGE-044"]?.content;

	const section3Title = copies["PAGE-045"]?.content;
	const section3Copy = copies["PAGE-046"]?.content;

	// HTML이 포함된 텍스트를 순수 텍스트로 변환
	const plainSlogonText = section1SlogonText
		? stripHtml(section1SlogonText)
		: "";

	const renderTypeAnimation = () => {
		if (!plainSlogonText) return null;

		return (
			<TypeAnimation
				sequence={[
					"", // 시작 시 빈 문자열
					100, // 시작 전 짧은 지연
					plainSlogonText, // 텍스트 타이핑
					2000, // 완성된 상태로 2초 대기
					() => {}, // 현재 시퀀스 종료 (텍스트 유지)
				]}
				wrapper="p"
				speed={50}
				className="text-[min(70px,5vw)] font-bold font-mont text-primary text-center w-full mx-auto"
				repeat={1}
				cursor={false}
				key={section1InView ? "typing-visible" : "typing-hidden"}
			/>
		);
	};

	return (
		<section
			className="pt-[min(105px,10vw)] pb-[min(169px,10vw)] relative overflow-hidden w-full"
			id="ci"
			ref={section1Ref}
		>
			{/* 섹션1 */}
			<div
				className={`max-w-[1232px] w-full mx-auto flex flex-col items-start mb-[35.17px] px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px]`}
			>
				<h2
					className={`text-[34px] tablet:text-[65px] font-bold mb-[10px] font-mont text-[#231815] ${
						section1InView ? "animate-fade-slide-up" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section1Title }}
				/>
				<p
					className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-[#3E3A39] ${
						section1InView ? "animate-fade-slide-up delay-[0.5s]" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section1Copy }}
				/>
			</div>
			<div
				className="w-full mt-[0px] relative aspect-[1924/460] bg-cover bg-center bg-no-repeat flex flex-col items-center justify-center mb-[min(211.29px,10vw)]"
				style={{
					backgroundImage: `url(${section1SlogonBgImage})`,
					backgroundSize: "contain",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			>
				{renderTypeAnimation()}
			</div>
			{/* 섹션2 */}
			<div
				ref={section2Ref}
				className={`max-w-[1232px] w-full mx-auto flex flex-col items-start mb-[min(212px,10vw)] px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px] ${
					section2InView ? "animate-fade-slide-up" : "opacity-0"
				}`}
			>
				<h2
					className={`text-[34px] tablet:text-[65px] font-bold mb-[10px] font-mont text-[#231815] ${
						section2InView ? "animate-fade-slide-up" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section2Title }}
				/>
				<p
					className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-[#3E3A39] mb-[37.83px] ${
						section2InView ? "animate-fade-slide-up delay-[0.5s]" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section2Copy }}
				/>
				<div
					className={`flex tablet:flex-row flex-col tablet:gap-[20px] gap-[40px] w-full ${
						section2InView ? "animate-fade-slide-up delay-[1s]" : "opacity-0"
					}`}
				>
					<div className="flex-1 flex-col items-center justify-center w-full">
						<img
							src={section2Logo1}
							alt="section2Logo1"
							className="w-full h-full object-cover"
						/>
					</div>
					<div className="flex-1 flex-col items-center justify-center w-full">
						<img
							src={section2Logo2}
							alt="section2Logo2"
							className="w-full h-full object-cover"
						/>
					</div>
				</div>
			</div>
			{/* 섹션3 */}
			<div
				ref={section3Ref}
				className={`max-w-[1232px] w-full mx-auto flex flex-col items-start mb-[min(35.17px,10vw)] px-[15px] tablet:px-[15px] desktop:px-[15px] wide:px-[0px] ${
					section3InView ? "animate-fade-slide-up" : "opacity-0"
				}`}
			>
				<h2
					className={`text-[34px] tablet:text-[65px] font-bold mb-[10px] font-mont text-[#231815] ${
						section3InView ? "animate-fade-slide-up" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section3Title }}
				/>
				<p
					className={`text-[14px] tablet:text-[20px] font-pretendard font-medium text-[#3E3A39] mb-[37.83px] ${
						section3InView ? "animate-fade-slide-up delay-[0.5s]" : "opacity-0"
					}`}
					dangerouslySetInnerHTML={{ __html: section3Copy }}
				/>
				<div
					className={`flex tablet:flex-row flex-col gap-[20px] w-full ${
						section3InView ? "animate-fade-slide-up delay-[1s]" : "opacity-0"
					}`}
				>
					<div className="tablet:w-[358.88px] tablet:h-[227.78px] w-[100%] aspect-[360/229] relative rounded-xl border-[1px] border-[#9fa0a0]/50 flex flex-col">
						<div className="flex flex-1 bg-primary rounded-[10px]"></div>
						<div className="absolute bottom-0 left-0 right-0 flex flex-col items-start justify-start bg-white rounded-b-[10px] px-[14px] py-[10px]">
							<div className="text-[#231815] text-xl font-semibold font-pretendard">
								Bongmaetteok’s Red color
							</div>
							<div className="text-[#595757] text-lg font-light font-pretendard">
								#D2191A
							</div>
						</div>
					</div>
					<div className="tablet:w-[358.88px] tablet:h-[227.78px] w-[100%] aspect-[360/229] relative bg-white rounded-xl border-[1px] border-[#9fa0a0]/50">
						<div className="absolute bottom-0 left-0 right-0 flex flex-col items-start justify-start bg-white rounded-b-[10px] px-[14px] py-[10px] border-t-[1px] border-[#9fa0a0]/50">
							<div className="text-[#231815] text-xl font-semibold font-pretendard">
								Bongmaetteok’s White color
							</div>
							<div className="text-[#595757] text-lg font-light font-pretendard">
								#FFFFFF
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BrandIdentitySection;
