import InstagramCard from "../news/InstagramCard";
import SkeletonInstagramCard from "./SkeletonInstagramCard";
import { useState, useEffect, forwardRef } from "react";

const InstagramGrid = forwardRef(({ posts, isLoading }, ref) => {
	const skeletonArray = Array(9).fill(null);

	return (
		<div ref={ref} className="flex flex-col items-center px-3 desktop:px-0">
			<div className="grid grid-cols-1 mobile:grid-cols-2 tablet:grid-cols-3 gap-[15.57px] mobile:gap-[15.57px] tablet:gap-[5.57px] desktop:gap-[35.57px] touch-pan-y touch-manipulation">
				{isLoading
					? skeletonArray.map((_, index) => (
							<SkeletonInstagramCard key={`skeleton-${index}`} />
					  ))
					: posts.map((post) => <InstagramCard key={post.id} post={post} />)}
			</div>
		</div>
	);
});

InstagramGrid.displayName = "InstagramGrid";

export default InstagramGrid;
