import useCopyStore from "../../services/copyService";
import { useEffect } from "react";
import searchButtonIcon from "../../assets/images/search_button.png";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const SearchStoreSection = () => {
	const { copies, getCopy } = useCopyStore();
	const { ref: sectionRef, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	useEffect(() => {
		const loadCopy = async () => {
			try {
				await getCopy("PAGE-004");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
	}, []);
	const bgImage = copies["PAGE-004"]?.media;
	const copy = copies["PAGE-004"]?.content?.replace(/<[^>]*>/g, "");
	return (
		<section
			ref={sectionRef}
			className="relative h-[210px] mobile:h-[330px] tablet:h-[522px] overflow-hidden transition-all duration-500"
		>
			<div
				className={`absolute inset-0 bg-cover bg-center transition-all duration-500
					${inView ? "animate-[zoomOut_5s_ease-out_forwards]" : ""}`}
				style={{
					backgroundImage: `url(${bgImage})`,
				}}
			/>
			<div className="relative h-full max-w-7xl mx-auto px-6 flex flex-col justify-center items-center text-center">
				<div className="flex items-center justify-center flex-col w-full">
					<h2
						className={`text-white font-mont font-black text-[30px] mobile:text-[40px] tablet:text-[60px] -mb-1 mobile:-mb-2 tablet:mb-1 ${
							inView
								? "animate-[slideUp_0.8s_ease-out_0s_forwards]"
								: "opacity-0"
						}`}
					>
						Search Store
					</h2>
					<h1
						className={`text-white font-pretendard font-medium tablet:text-[10px] text-[12px] mobile:text-[14px] tablet:text-[21px] mb-[27px] mobile:mb-[30px] tablet:mb-[74px] ${
							inView
								? "animate-[slideUp_0.8s_ease-out_0.2s_forwards]"
								: "opacity-0"
						}`}
					>
						{copy || "로딩중.."}
					</h1>
					{/* 검색창 */}
					<Link to="/locations" className="w-full flex justify-center">
						<div
							className={`flex items-center gap-8 rounded-full p-[3px] mobile:p-[5px] tablet:p-[7.26px] bg-white transition-all duration-1000 ease-out cursor-pointer ${
								inView
									? "w-[90%] mobile:w-[80%] tablet:w-[80%]"
									: "w-[82px] mobile:w-[82px] tablet:w-[82px]"
							} max-w-[801px]`}
						>
							{inView && (
								<input
									type="text"
									placeholder="매장 바로가기"
									className={`bg-transparent border-none outline-none font-pretendard font-semibold text-[12px] mobile:text-[14px] tablet:text-[20px] transition-all duration-700 cursor-pointer ${
										inView
											? "w-full tablet:ml-[30px] ml-[15px] opacity-100"
											: "w-0 ml-0 opacity-0 p-0"
									}`}
									readOnly
								/>
							)}
							<img
								src={searchButtonIcon}
								alt="검색 버튼"
								className="w-[36px] h-[36px] mobile:w-[50px] mobile:h-[50px] tablet:w-[68px] tablet:h-[68px]"
							/>
						</div>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default SearchStoreSection;
