import OpeningInquiryFormSection from "../sections/franchise/OpeningInquiryFormSection";
import { Toaster } from "react-hot-toast";
import useCopyStore from "../services/copyService";
import { useMediaQuery, BREAKPOINTS } from "../hooks/useMediaQuery";
import useScrollStore from "../store/scrollStore";
import { useEffect, useRef, useState } from "react";
import FloatingBar from "../sections/franchise/FloatingBar";
import { Helmet } from "react-helmet-async";
import Footer from "../components/layout/Footer";
import Scrollbar from "smooth-scrollbar";

const FranchisePage = () => {
	const scrollbarRef = useRef(null);
	const setScrollY = useScrollStore((state) => state.setScrollY);
	const { copies, getCopy } = useCopyStore();
	const isMobileOrTablet = useMediaQuery(BREAKPOINTS.TABLET);
	const [isFooterVisible, setIsFooterVisible] = useState(false);
	const [footerHeight, setFooterHeight] = useState(0);
	const [scrollPosition, setScrollPosition] = useState(0);
	const FLOATING_OFFSET = 205; // FloatingBar가 하단에서 떨어질 거리 (px)

	useEffect(() => {
		const loadCopy = async () => {
			try {
				getCopy("PAGE-009");
				getCopy("PAGE-010");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
		window.scrollTo(0, 0); // 페이지 최상단으로 스크롤
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsFooterVisible(entry.isIntersecting);
				if (entry.isIntersecting) {
					// Footer의 실제 높이를 측정
					const footer = document.querySelector("footer");
					if (footer) {
						setFooterHeight(footer.offsetHeight);
					}
				}
			},
			{ threshold: 0 }
		);

		const footer = document.querySelector("footer");
		if (footer) {
			observer.observe(footer);
			// 초기 Footer 높이 설정
			setFooterHeight(footer.offsetHeight);
		}

		return () => {
			if (footer) {
				observer.unobserve(footer);
			}
		};
	}, []);

	useEffect(() => {
		// smooth-scrollbar 초기화
		const scrollContainer = document.querySelector("#my-scrollbar");
		if (scrollContainer) {
			scrollbarRef.current = Scrollbar.init(scrollContainer, {
				damping: 0.1,
				thumbMinSize: 20,
				renderByPixels: true,
				alwaysShowTracks: false,
				continuousScrolling: true,
			});

			// 스크롤 이벤트 리스너 추가
			scrollbarRef.current.addListener(({ offset }) => {
				setScrollY(offset.y);
			});

			// 초기 위치 설정
			scrollbarRef.current.setPosition(0, 0);

			return () => {
				if (scrollbarRef.current) {
					scrollbarRef.current.destroy();
					scrollbarRef.current = null;
				}
			};
		}
	}, [setScrollY]);

	// useEffect(() => {
	// 	let rafId;
	// 	let lastScrollY = window.scrollY;
	// 	let ticking = false;

	// 	const handleScroll = () => {
	// 		lastScrollY = window.scrollY;

	// 		if (!ticking) {
	// 			rafId = requestAnimationFrame(() => {
	// 				const windowHeight = window.innerHeight;
	// 				const documentHeight = document.documentElement.scrollHeight;

	// 				if (lastScrollY + windowHeight >= documentHeight - FLOATING_OFFSET) {
	// 					const difference =
	// 						lastScrollY + windowHeight - (documentHeight - FLOATING_OFFSET);
	// 					setScrollPosition(difference);
	// 				} else {
	// 					setScrollPosition(0);
	// 				}

	// 				ticking = false;
	// 			});
	// 			ticking = true;
	// 		}
	// 	};

	// 	window.addEventListener("scroll", handleScroll, { passive: true });
	// 	handleScroll(); // 초기 위치 설정

	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 		if (rafId) {
	// 			cancelAnimationFrame(rafId);
	// 		}
	// 	};
	// }, []);

	const bannerImage = copies["PAGE-009"]?.media;
	const mobileBannerImage = copies["PAGE-010"]?.media;
	const bannerCopy = copies["PAGE-009"]?.content;
	return (
		<>
			<Helmet>
				<title>가맹문의 | 봉천동 매운 떡볶이</title>
				<meta
					name="description"
					content="봉천동 매운 떡볶이와 함께 성공적인 창업을 시작하세요. 체계적인 창업 지원 시스템과 노하우를 제공합니다."
				/>
				<meta
					name="keywords"
					content="봉천동 매운 떡볶이 창업, 분식 창업, 프랜차이즈 창업"
				/>
				<link rel="canonical" href="https://bongmaetteok.co.kr/franchise" />
			</Helmet>
			<div className="mx-auto flex flex-col items-center bg-[#F9F9F9]">
				<Toaster position="top-center" />
				<div
					id="my-scrollbar"
					className="w-full h-screen flex flex-col items-center overflow-hidden"
				>
					<div className="content w-full">
						{/* 배너 */}
						<div className="relative w-full bg-white  flex items-center justify-center font-pretendard translate-y-[-1px]">
							<img
								src={isMobileOrTablet ? mobileBannerImage : bannerImage}
								alt="메뉴 배너"
								className="w-full h-auto tablet:min-h-[385px] min-h-[275px] object-cover"
							/>
							<div className="absolute bottom-0 left-0 w-full h-full flex flex-col items-center justify-center">
								<div className="text-[34px] tablet:text-[68px] font-extrabold text-white text-center animate-fade-slide-up [animation-delay:0.2s] opacity-0">
									Franchise
								</div>
								<div className="text-[24px] tablet:text-[54px] font-bold text-white text-center mt-[10.24px] mb-[10.24px] animate-fade-slide-up [animation-delay:0.4s] opacity-0">
									가맹문의
								</div>
								<div
									className="text-[14px] tablet:text-[21px] font-medium text-white text-center tablet:max-w-full max-w-[310px] animate-fade-slide-up [animation-delay:0.6s] opacity-0"
									dangerouslySetInnerHTML={{ __html: bannerCopy }}
								/>
							</div>
						</div>

						<div className="px-4 mobile:px-6 w-full animate-fade-slide-up [animation-delay:0.8s] opacity-0">
							<OpeningInquiryFormSection />
						</div>
						<Footer />
					</div>
				</div>
				<div className="fixed w-full flex justify-center bottom-6 left-0 right-0 z-20 transition-all duration-300">
					<FloatingBar isFooterVisible={isFooterVisible} />
				</div>
			</div>
		</>
	);
};

export default FranchisePage;
