import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

// API 함수
const fetchInstagramPosts = async () => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/instagrams/`);
		if (!response.ok)
			throw new Error("인스타그램 포스트를 불러오는데 실패했습니다");
		return await response.json();
	} catch (error) {
		console.error("Error fetching instagram posts:", error);
		throw error;
	}
};

// Zustand store
const useInstagramStore = create((set, get) => ({
	// 상태
	posts: [],
	loading: false,
	error: null,
	selectedPost: null,

	// 액션
	getPosts: async () => {
		try {
			// 이미 데이터가 있는지 확인
			const existingPosts = get().posts;
			if (existingPosts.length > 0) return;

			set({ loading: true, error: null });
			const data = await fetchInstagramPosts();
			set({
				posts: data,
				loading: false,
			});
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
		}
	},

	setSelectedPost: (postId) => {
		const post = get().posts.find((p) => p.id === postId);
		set({ selectedPost: post });
	},

	// 초기화
	reset: () => {
		set({
			posts: [],
			loading: false,
			error: null,
			selectedPost: null,
		});
	},
}));

export default useInstagramStore;
