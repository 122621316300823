import { BASE_URL } from "./apiConfig";
import { create } from "zustand";

// API 함수
const fetchMenuCategories = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/menu-categories`);
		if (!response.ok)
			throw new Error("메뉴 카테고리를 불러오는데 실패했습니다");
		return await response.json();
	} catch (error) {
		console.error("Error fetching menu categories:", error);
		throw error;
	}
};

// Zustand store
const useMenuStore = create((set, get) => ({
	// 상태
	categories: [],
	loading: false,
	error: null,
	selectedCategory: null,

	// 액션
	getCategories: async () => {
		try {
			// 이미 데이터가 있는지 확인
			const existingCategories = get().categories;
			if (existingCategories.length > 0) return;

			set({ loading: true, error: null });
			const data = await fetchMenuCategories();
			set({
				categories: data,
				loading: false,
			});
			return data;
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
		}
	},

	setSelectedCategory: (category) => {
		set({ selectedCategory: category });
	},

	// 초기화
	reset: () => {
		set({
			categories: [],
			loading: false,
			error: null,
			selectedCategory: null,
		});
	},
}));

export default useMenuStore;
