import koreaRegions from "../data/koreaRegions.json";

// 주소 검색
export const searchAddresses = (query) => {
	return new Promise((resolve, reject) => {
		window.naver.maps.Service.geocode(
			{
				query: query,
			},
			function (status, response) {
				if (status === window.naver.maps.Service.Status.OK) {
					resolve(response.v2.addresses);
				} else {
					reject(new Error("주소 검색 실패"));
				}
			}
		);
	});
};

// 현재 위치 가져오기
export const getCurrentLocation = () => {
	return new Promise((resolve, reject) => {
		if (!navigator.geolocation) {
			reject(new Error("Geolocation이 지원되지 않습니다."));
			return;
		}

		navigator.geolocation.getCurrentPosition(
			(position) => {
				resolve({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			},
			(error) => {
				reject(error);
			}
		);
	});
};

// 시/도 목록
export const cities = Object.keys(koreaRegions);

// 구/군 검색
export const searchDistricts = async (city) => {
	try {
		const districts = koreaRegions[city] || [];
		return districts.map((name) => ({ name }));
	} catch (error) {
		console.error("구/군 검색 실패:", error);
		return [];
	}
};
